import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { Button } from '@material-ui/core';

const Slido = (): JSX.Element => {
  const [open, setOpen] = useState(false);

  const getQueryStringValue = (key: string) => decodeURIComponent(window.location.search.replace(new RegExp(`^(?:.*[&\\?]${encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&')}(?:\\=([^&]*))?)?.*$`, 'i'), '$1'));

  const url = getQueryStringValue('url');
  const slido = getQueryStringValue('slido');

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    // ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <div className="url">
      {!open &&
      <div className="url__iframe-container">
        <div className="url__iframe-wrapper">
          <iframe
            sandbox="allow-same-origin allow-forms allow-scripts"
            allowFullScreen
            allow="camera; microphone; fullscreen; speaker; display-capture"
            src={url}
            frameBorder="0"
            title="video"
          />
        </div>
      </div>
      }
      {open &&
      <div className="url__iframes-wrap">
        <div className="url__iframes">
          <iframe
            sandbox="allow-same-origin allow-forms allow-scripts"
            allowFullScreen
            allow="camera; microphone; fullscreen; speaker; display-capture"
            src={url}
            frameBorder="0"
            title="video"
            height="100%"
            width="70%"
          />
          <iframe
            src={slido || 'https://app.sli.do/event/yhqdlzih'}
            height="100%"
            width="30%"
            title="slido"
            frameBorder="0"
          />
        </div>
        {
          slido && open &&
          <div className="url__button">
            <Button variant="contained" onClick={closeModal}>Cancel</Button>
          </div>
        }
      </div>
      }
      {slido && !open &&
        <div className="url__button-wrapper">
          <Button variant="contained" onClick={openModal}>Submit a question</Button>
        </div>
      }
    </div>
  );
};

export default Slido;
