import React from 'react';
import { Button, ButtonProps, IconButtonProps } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

export const ToolTippedButtonStyles = makeStyles({
  button: {
    color: 'black',
    background: '#ffffff',
    boxShadow: '0 2px 2px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    height: 50,
    fontFamily: 'inherit',
    textTransform: 'none',
  },
});

export interface ToolTippedIconButtonProps extends IconButtonProps {
  tooltip: string;
}

export const ToolTippedIconButton = (props: ToolTippedIconButtonProps): JSX.Element => {
  const { tooltip, className, children, ...others } = props;

  const styles = ToolTippedButtonStyles();

  return (
    <Tooltip title={tooltip}>
      <IconButton {...others} className={[styles.button, className].join(' ')}>
        {children}
      </IconButton>
    </Tooltip>
  );
};

export interface ToolTippedButtonProps extends ButtonProps {
  tooltip: string;
}

export const ToolTippedButton = (props: ToolTippedButtonProps): JSX.Element => {
  const { tooltip, className, children, ...others } = props;

  const styles = ToolTippedButtonStyles();

  return (
    <Tooltip title={tooltip}>
      <Button {...others} className={[styles.button, className].join(' ')}>
        {children}
      </Button>
    </Tooltip>
  );
};
export default ToolTippedButton;
