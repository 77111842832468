import React, { useMemo } from 'react';
import LineChart from '../LineChart';
import DESIGN from '../../../constants/designConstants';
import { IVisitorDetails } from '../../../utilities/backendTypes';
import { NAnalytics } from '../../../service/transportTypes/NAnalytics';
import {
  aggregateAnalytics,
  pickBestAggregate,
  prepareCSVDataFromDataLines,
} from '../../../utilities/graphUtils';
import DownloadCSVButton from '../../base/DownloadCSVButton';

export interface UserRegistrationsChartProps {
  period: Interval;
  visitors: IVisitorDetails[];
  loginEvents: NAnalytics.ILoginEvent[];
  deletionEvents: NAnalytics.IDeleteEvent[];
}

const UserRegistrationChart = (props: UserRegistrationsChartProps): JSX.Element => {
  const { period, visitors, loginEvents, deletionEvents } = props;

  // Aggregating raw data into buckets
  const visitorsData = useMemo(
    () =>
      aggregateAnalytics(
        visitors.map((v) => ({
          ...v,
          date: new Date(v.created_at),
        })),
        period,
        pickBestAggregate(period),
      ),
    [visitors, period],
  );
  const loginData = useMemo(
    () =>
      aggregateAnalytics(
        loginEvents.map((v) => ({
          ...v,
          date: new Date(v.created_at),
        })),
        period,
        pickBestAggregate(period),
      ),
    [loginEvents, period],
  );
  const deletionData = useMemo(
    () =>
      aggregateAnalytics(
        deletionEvents.map((v) => ({
          ...v,
          date: new Date(v.created_at),
        })),
        period,
        pickBestAggregate(period),
      ),
    [deletionEvents, period],
  );

  // Converting aggregate data into graph lines
  const registrationPlot = useMemo(
    () =>
      visitorsData.map((d) => ({
        x: d.date,
        y: d.items.length,
      })),
    [visitorsData],
  );
  const totalLoginsPlot = useMemo(
    () =>
      loginData.map((d) => ({
        x: d.date,
        y: d.items.length,
      })),
    [loginData],
  );
  const uniqueLoginsPlot = useMemo(
    () =>
      loginData.map((d) => ({
        x: d.date,
        y: d.items
          .map((i) => i.visitor_user_id)
          .filter((id, index, list) => list.indexOf(id) === index).length,
      })),
    [loginData],
  );
  const deletionPlot = useMemo(
    () =>
      deletionData.map((d) => ({
        x: d.date,
        y: d.items.length,
      })),
    [deletionData],
  );

  const dataLines = useMemo(
    () => [
      {
        title: 'Registrations',
        points: registrationPlot,
        colour: DESIGN.COLOR_GREEN,
      },
      {
        title: 'Logins',
        points: totalLoginsPlot,
        colour: DESIGN.COLOR_BLUE,
      },
      {
        title: 'Users',
        points: uniqueLoginsPlot,
        colour: DESIGN.COLOR_DARK_BLUE,
      },
      {
        title: 'Deletions',
        points: deletionPlot,
        colour: DESIGN.COLOR_RED,
      },
    ],
    [registrationPlot, totalLoginsPlot, uniqueLoginsPlot],
  );

  const csvData = useMemo(() => prepareCSVDataFromDataLines(dataLines), [dataLines]);

  // console.log('Interval: ', interval);
  // console.log('Data: ', {
  //   visitors,
  //   visitorsData,
  //   loginEvents,
  //   loginData,
  // });

  // console.log('Plots: ', {
  //   registrationPlot,
  //   totalLoginsPlot,
  //   uniqueLoginsPlot,
  // });

  return (
    <LineChart
      name="User Registration/Logins"
      category="Total"
      data={dataLines}
      actionButtons={[
        <DownloadCSVButton downloadData={csvData} fileName="User Registration/Logins" />,
      ]}
    />
  );
};

export default UserRegistrationChart;
