import React from 'react';
import { Grid } from '@material-ui/core';
import logo from '../assets/images/logo.png';

const TermsOfUse = (): JSX.Element => (
  <Grid className="privacy" container direction="column" item xs={12}>
    <Grid container justifyContent="space-between" alignItems="center" className="privacy__header">
      <h1 className="privacy__title">Terms of Use Cardio Lounge</h1>
      <img src={logo} alt="Logo" />
    </Grid>
    <p>
      Welcome to this website (
      <a href="https://menarinicardiolounge.com/)" target="_blank" rel="noreferrer">
        https://menarinicardiolounge.com/
      </a>) ("Website").
      Please read and review the Terms of Use carefully before accessing or
      using this Website. By accessing or using this Website, you acknowledge
      that you have read, understood and agreed to the Terms of Use Agreement.
      If you do not agree to any of the Terms of Use, you may not access or use this Website.
    </p>

    <p><span className="bold">Access and Use of this Website. </span>
      You understand, acknowledge and agree that:
    </p>
    <ul>
      <li>
        This Website is owned by A. Menarini Asia-Pacific Holdings Pte. Ltd.
        The information provided on this Website including any information from A.
        Menarini Asia-Pacific Holdings Pte. Ltd., its subsidiaries and affiliates
        (collectively referred to as "Menarini") is intended
        for general informational purposes only.
        It is not intended to be a substitute for medical diagnosis or treatment,
        or medical or healthcare advice by doctors or other healthcare professionals and
        nothing in this Website shall be construed as the giving of advice or the making
        of recommendation regarding any decision or action relating to your health or
        the health of any other person. Please consult with your doctor or other
        healthcare professional for medical advice or information or
        regarding any questions you may have about any health issues
        (including the diagnosis and treatment of your condition).
      </li>
      <li>This Website may allow users to post, publish, send,
        upload and/or otherwise communicate information and other data ("User Content").
        The person who posts User Content, and not Menarini, is solely responsible for
        such User Content and solely liable for any and all loss or damage caused
        by such User Content. Menarini does not control User Content
        and takes no responsibility for the accuracy,
        integrity or quality of any such User Content.
        To the maximum extent allowed by applicable laws,
        under no circumstances will Menarini be liable in any way for any User Content,
        including but not limited to any errors, omissions or misrepresentations in any
        User Content, or for any loss or damage arising from any User Content and/or
        any use thereof.
      </li>
      <li>If you post, publish, send, upload and/or otherwise communicate User Content
        on the Website, such User Content shall be deemed
        as non-confidential information and will be available publicly.
        You further represent and warrant that:
        <ul>
          <li>you own or otherwise control all of the rights to such User Content;</li>
          <li>the User Content is true and accurate;</li>
          <li>you are responsible for your User Content,
            and you shall ensure that the User Content
            is not illegal, obscene, abusive, threatening,
            defamatory, invasive of privacy, infringing of
            intellectual property rights, or otherwise injurious
            to third parties, or objectionable and does not consist
            of or contain software viruses, political campaigning,
            commercial solicitation, chain letters, mass mailings,
            or any form of "spam".
          </li>
        </ul>
      </li>
      <p>By posting, publishing, sending, uploading and/or otherwise
        communicating any User Content to us, you agree to grant
        Menarini a non-exclusive, irrevocable, royalty-free, worldwide,
        transferable and sub-licensable licence to use such User Content
        in any manner and for any purpose whatsoever in connection with
        this Website without any obligation of any kind to you with respect to such User Content,
        subject to our Terms of Use and Privacy Policy.
      </p>
      <li>You must use the Website for lawful purposes only in
        accordance with our Terms of Use and Privacy
        Policy and you shall not use the Website in any way that causes, or is likely to
        cause, the Website or access to it to be interrupted, damaged or impaired in any way.
      </li>
      <li>You grant to Menarini the rights to collect, use,
        disclose and Process (as defined in the Privacy Policy)
        any and all information
        (including your Personal Data (as defined in the Privacy Policy) and User Content
        which you upload or transmit to Menarini via this Website, in any manner which Menarini
        chooses, including but not limited to copying, displaying or publishing it in any format
        whatsoever, modifying it, incorporating it into other material or making a derivative work
        based on it, subject to our Terms of Use and Privacy Policy.
        To the maximum extent allowed by applicable
        law, you waive any moral rights you may have to the content
        you upload or otherwise transmit on this Website.
      </li>
      <li>You are responsible for ensuring that your access to
        this Website is permissible under the laws of the jurisdiction in which
        you are located, and shall not access this Website
        from jurisdictions where the access of this Website
        and/or any of its contents are not legally permissible.
      </li>
      <p><span className="bold">Intellectual Property. </span>
        Any right, title and interest in and to the content displayed on this Website,
        including but not limited to this Website's look and feel, data, information,
        text, graphics, images, sound or video materials, photographs, designs, Trademarks,
        URLs (individually or collectively, the "Content") is owned by Menarini and its
        licensors or joint venture partners (as applicable). This Website is published
        by A. Menarini Asia-Pacific Holdings Pte. Ltd, and is protected by applicable
        law, including copyright laws. The use or misuse of the Content or any other
        material on this Website is expressly prohibited. The Content of this Website
        may not be used, downloaded (other than via page caching) or copied other than
        for personal use and non-commercial purposes and shall not be reproduced,
        modified or otherwise redistributed in any means or manner. Without prejudice
        to the generality of the foregoing, use of any of the Trademarks may not be made
        without the prior, written authorisation of Menarini and/or its licensors or joint
        venture partners, except to identify the products or services of Menarini and/or its
        licensors or joint venture partners. For the purposes of this clause,
        "Trademarks" refer to any trademarks, service marks,
        trade names and trade dress featured on this Website.
      </p>
      <p><span className="bold">Content. </span>Menarini makes no representations,
        warranties or assurances as to the accuracy or completeness of the Content
        provided. To the maximum extent allowed by applicable law, Menarini
        shall not be liable for any damages or injury resulting from your access to,
        or inability to access this Website, or from your reliance on any Content
        provided herein. You agree that access to and use of this Website and its
        Content is at your own risk.
      </p>
      <p><span className="bold">Third Party Websites. </span>
        This Website may contain links
        or references to other Websites which are maintained by third
        parties over whom Menarini has no control. Menarini makes no
        warranties or representations of any kind as to the accuracy
        or completeness of any information provided in third party
        websites, has no responsibility for such information and
        shall not be liable for any damages or injury arising from
        such information. Any links to third party websites are
        provided as merely a convenience to the users of this Website
        and their inclusion in this Website does not, in any circumstances,
        mean that Menarini agrees with, or endorses, the contents therein.
        Such third party websites usually have their own terms and conditions,
        including privacy policies, over which we have no control and which
        will govern your rights and obligations with respect to the use of
        those websites and resources. Your access to and/or use of such third
        party websites and resources is at your own risk.
      </p>
      <p><span className="bold">Privacy. </span> All users' rights and responsibilities with respect to information (including Personal Data as defined in the Privacy Policy) disclosed on this Website shall be governed by our Privacy Policy. By accessing and using this Website, you agree to be bound by our Privacy Policy.</p>
      <p><span className="bold">Governing Law. </span>
        This Terms of Use Agreement,
        the Privacy Policy and your use of this Website shall be governed by and
        construed in accordance with the laws of Singapore and any legal action or proceeding
        in relation to this Website shall be subject to the exclusive jurisdiction
        of the courts of Singapore.
      </p>
    </ul>
  </Grid>
);

export default TermsOfUse;
