import React from 'react';
import logo from '../assets/images/logo.png';

const ZoomThanks = (): JSX.Element => (
  <div className="zoom-thanks">
    <div className="zoom-thanks__message">
      <p>Thank you for attending our webinar.</p>
      <p>You can close this pop-up to enjoy the Menarini Cardio Lounge.</p>
    </div>
    <img src={logo} alt="Logo" />
    <iframe
      sandbox="allow-same-origin allow-forms allow-scripts"
      allowFullScreen
      className="iframe"
      title="survey"
      src="https://www.surveymonkey.com/r/K92PWFX"
    />
  </div>
);

export default ZoomThanks;
