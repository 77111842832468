import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { Filter } from '../components/base/Filter';
import { IVisitorDetails } from '../utilities/backendTypes';

export interface UIState {
  userFilters?: Filter<IVisitorDetails>[];
  userSearch?: string;
  dataPeriodStart?: number;
  dataPeriodEnd?: number;
}

export const initialState: UIState = {} as const;

export const slice = createSlice({
  name: 'ui-data',
  initialState,
  reducers: {
    setUserFilters(state, action: PayloadAction<Filter<IVisitorDetails>[] | undefined>) {
      state.userFilters = action.payload;
    },
    setUserSearch(state, action: PayloadAction<string | undefined>) {
      state.userSearch = action.payload;
    },
    setDataPeriodStart(state, action: PayloadAction<Date | null>) {
      state.dataPeriodStart = action.payload?.valueOf() ?? undefined;
    },
    setDataPeriodEnd(state, action: PayloadAction<Date | null>) {
      state.dataPeriodEnd = action.payload?.valueOf() ?? undefined;
    },
  },
});

const persistConfig = {
  key: 'root',
  storage,
};

export const {
  setUserFilters,
  setUserSearch,
  setDataPeriodStart,
  setDataPeriodEnd,
} = slice.actions;
export default persistReducer(persistConfig, slice.reducer);
