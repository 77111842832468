import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import Fuse from 'fuse.js';
import AuthorizedPage from '../containers/AuthorizedPage';
import PageHeader from '../components/base/PageHeader';
import { useAppDispatch, useAppSelector } from '../store/store';
import TableDefault, { ColumnData } from '../components/base/TableDefault';
import { IVisitorDetails } from '../utilities/backendTypes';
import { loadVisitors } from '../store/userSlice';
import PATHS from '../constants/pathConstants';
import { applyFilter, Filter } from '../components/base/Filter';
import { NAnalytics } from '../service/transportTypes/NAnalytics';
import { getLoginData, getTimeSpentData } from '../store/analyticsSlice';
import analyticsService from '../service/analyticsService';
import { setUserFilters, setUserSearch } from '../store/uiSlice';
import useDataPeriodInterval from '../helpers/hooks/useDataPeriodInterval';
import SettingsButton from '../components/base/SettingsButton';
import DataRangeSettingsOverlay from '../components/overlays/DataRangeSettingsOverlay';
import { isDateInInterval } from '../utilities/graphUtils';

type UserFilter = Filter<IVisitorDetails>;

const columns: ColumnData<IVisitorDetails>[] = [
  {
    id: 'title',
    type: 'string',
    label: 'Title',
  },
  {
    id: 'first_name',
    type: 'string',
    label: 'First Name',
  },
  {
    id: 'last_name',
    type: 'string',
    label: 'Last Name',
  },
  {
    id: 'menarini_uc',
    type: 'string',
    label: 'Universal Code',
  },
  {
    id: 'specialty',
    type: 'string',
    label: 'Specialty',
  },
  {
    id: 'country',
    type: 'string',
    label: 'Country',
  },
  {
    id: 'total_logins',
    type: 'number',
    label: 'Total Logins',
  },
  {
    id: 'time_spent',
    type: 'number',
    label: 'Total Time Spent (Min)',
  },
];

const collectLoginData = (
  logins: NAnalytics.ILoginEvent[],
  selectedUser: number,
  period: Interval,
) =>
  logins.filter(
    (login) => login.visitor_user_id === selectedUser && isDateInInterval(login.created_at, period),
  );

const fuseOptions = {
  includeScore: true,
  minMatchCharLength: 3,
  keys: ['first_name', 'last_name', 'country'],
};

const Users = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { tokenVerified, visitors } = useAppSelector((state) => state.user);
  const { loginData, timeSpent } = useAppSelector((state) => state.analytics);
  const { userFilters, userSearch } = useAppSelector((state) => state.uiData);

  const [data, setData] = useState<IVisitorDetails[]>(visitors);
  const [searchInputData, setSearchInputData] = React.useState(userSearch ?? '');
  const [settingsOpen, setSettingsOpen] = useState(false);

  const period = useDataPeriodInterval();
  const fuse = useMemo(() => new Fuse(data, fuseOptions), [data]);

  useEffect(() => {
    if (tokenVerified) {
      dispatch(getLoginData());
      dispatch(loadVisitors());
      dispatch(getTimeSpentData());
    }
  }, [tokenVerified]);

  useEffect(() => {
    const formattedVisitors = visitors
      .map((visitor) => {
        const login = collectLoginData(loginData, visitor.id, period);
        const dataSpent = analyticsService.collectTimePerRoomData(
          timeSpent.filter((e) => e.visitor_user_id === visitor.id),
          period,
        );
        const totalTimeSpent = dataSpent.reduce((acc, time) => acc + (time.data ?? 0), 0);
        return {
          ...visitor,
          total_logins: login.length,
          time_spent: parseInt(totalTimeSpent.toFixed(0)),
        };
      })
      .filter(
        (visitor) =>
          isDateInInterval(visitor.created_at, period) ||
          visitor.total_logins > 0 ||
          visitor.time_spent > 0,
      );
    setData(formattedVisitors);
  }, [visitors, loginData, timeSpent, period]);

  const handleMoreInfo = (user: IVisitorDetails) => {
    history.push(`${PATHS.USER_DATA}?user=${user.id}`);
  };

  const handleFilters = (filtersData: UserFilter[]) => {
    dispatch(setUserFilters(filtersData));
  };

  const handleSearch = () => {
    dispatch(setUserSearch(searchInputData));
    // console.log('Searching', searchInputData);
  };

  const handleClearSearch = () => {
    dispatch(setUserSearch(undefined));
    setSearchInputData('');
  };

  let filteredData = data;
  if (userSearch) {
    filteredData = fuse
      .search(userSearch)
      .filter((r) => r.score && r.score < 0.5)
      .map((r) => r.item);
    // console.log('Searched', userSearch, filteredData);
  }

  if (userFilters) {
    filteredData = filteredData.filter((visitor) =>
      userFilters.every((filter) => applyFilter(visitor, filter)),
    );
  }

  // Remap field names for csv file
  const csvData = filteredData.map(
    ({
      id,
      title,
      first_name,
      last_name,
      specialty,
      country,
      menarini_uc,
      created_at,
      lastLogin,
      total_logins,
      time_spent,
    }) => ({
      id,
      title,
      firstName: first_name,
      lastName: last_name,
      specialty,
      country,
      universalCode: menarini_uc,
      registeredDate: created_at,
      lastLoginDate: lastLogin,
      totalLogins: total_logins,
      timeSpentMinutes: time_spent,
    }),
  );

  return (
    <AuthorizedPage>
      <PageHeader
        title="Users"
        hintMessage="View users"
        actions={[<SettingsButton onClick={() => setSettingsOpen(true)} />]}
      />
      <Grid className="dashboard-body">
        <Grid item>
          {data && (
            <TableDefault<IVisitorDetails>
              columns={columns}
              rows={filteredData}
              csvData={csvData}
              csvFile="users"
              filters={userFilters ?? []}
              actionsColumnHeading="More Info"
              actions={[
                {
                  icon: ChevronRight,
                  action: handleMoreInfo,
                  tooltip: 'More Info',
                  key: 'more-info',
                },
              ]}
              hideAddButton
              hideDeleteButton
              hideDownloadButton={false}
              hideSearchButton={false}
              viewDataOnly
              onFiltersUpdate={handleFilters}
              handleSearch={handleSearch}
              setSearchInputData={setSearchInputData}
              searchInputData={searchInputData}
              handleClearSearch={handleClearSearch}
            />
          )}
        </Grid>
      </Grid>
      <DataRangeSettingsOverlay
        open={settingsOpen}
        period={period}
        onClose={() => setSettingsOpen(false)}
      />
    </AuthorizedPage>
  );
};

export default Users;
