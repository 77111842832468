import {
  differenceInCalendarDays,
  differenceInCalendarMonths,
  differenceInHours,
  eachDayOfInterval,
  eachHourOfInterval,
  eachMonthOfInterval,
  eachQuarterOfInterval,
  eachWeekOfInterval,
  eachYearOfInterval,
} from 'date-fns';
import { KeysOf } from '../service/transportTypes/TransportTypes';
import { DataLine } from '../components/analytics/LineChart';
import { DataSlice } from '../components/analytics/PieChart';
import { NAnalytics } from '../service/transportTypes/NAnalytics';

export const AggregatePeriods = {
  HOUR: 'hour',
  THREE_HOUR: '3hour',
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  QUARTER: 'quarter',
  YEAR: 'year',
} as const;

export type AggregatePeriod = KeysOf<typeof AggregatePeriods>;

interface AggregateBucket<T extends { date: Date }> {
  date: Date;
  items: T[];
}

export function isDateInInterval(date: Date | string | number, period: Interval): boolean {
  const createdAt = new Date(date);
  return period.start <= createdAt && createdAt <= period.end;
}

export function pickBestAggregate(interval: Interval, maxNodes: number = 28): AggregatePeriod {
  // console.log('pickBestAggregate', maxNodes);

  const hours = differenceInHours(interval.end, interval.start);
  // console.log('hours', hours);

  if (hours <= maxNodes) {
    return AggregatePeriods.HOUR;
  }

  if (hours / 3 <= maxNodes) {
    return AggregatePeriods.THREE_HOUR;
  }

  const days = differenceInCalendarDays(interval.end, interval.start);
  // console.log('days', days);

  if (days <= maxNodes) {
    return AggregatePeriods.DAY;
  }

  if (days / 7 <= maxNodes) {
    return AggregatePeriods.WEEK;
  }

  const months = differenceInCalendarMonths(interval.end, interval.start);
  // console.log('months', months);

  if (months <= maxNodes) {
    return AggregatePeriods.MONTH;
  }

  if (months / 3 <= maxNodes) {
    return AggregatePeriods.QUARTER;
  }

  return AggregatePeriods.YEAR;
}

export function generateBuckets(interval: Interval, aggregate: AggregatePeriod) {
  switch (aggregate) {
    case 'hour':
      return eachHourOfInterval(interval);
    case '3hour':
      return eachHourOfInterval(interval).filter((d, i) => i % 3 === 0);
    case 'day':
      return eachDayOfInterval(interval);
    case 'week':
      return eachWeekOfInterval(interval);
    case 'month':
      return eachMonthOfInterval(interval);
    case 'quarter':
      return eachQuarterOfInterval(interval);
    case 'year':
      return eachYearOfInterval(interval);
    default:
      return [];
  }
}

export function aggregateAnalytics<T extends { date: Date }>(
  items: T[],
  interval: Interval,
  aggregate: AggregatePeriod,
  map: AggregateBucket<T>[] = generateBuckets(interval, aggregate).map<AggregateBucket<T>>(
    (date) => ({ date, items: [] }),
  ),
): AggregateBucket<T>[] {
  const buckets = map;
  const sortedItems = items.sort((a, b) => a.date.valueOf() - b.date.valueOf());

  let itemIndex = 0;
  let bucketIndex = 0;
  while (bucketIndex < buckets.length && itemIndex < sortedItems.length) {
    const nextBucket = buckets[bucketIndex];
    const item = sortedItems[itemIndex];

    // console.log(`Bucket ${bucketIndex}, Item ${itemIndex}`);
    if (item.date < nextBucket.date) {
      if (bucketIndex > 0) {
        buckets[bucketIndex - 1].items.push(item);
        // console.log('Added');
      } else {
        // console.log('Before start of interval');
      }
      itemIndex += 1;
    } else {
      // console.log('Check next bucket');
      bucketIndex += 1;
    }
  }

  return buckets;
}

export interface ContentDataCollection {
  all: NAnalytics.IContentTriggerEvent[];
  [key: string]: NAnalytics.IContentTriggerEvent[];
}

export interface CollectContentDataReturn {
  videos: ContentDataCollection;
  links: ContentDataCollection;
  podcasts: ContentDataCollection;
}

export function collectContentData(
  contentTriggers: NAnalytics.IContentTriggerEvent[],
  period: Interval,
): CollectContentDataReturn {
  // Collect watch/download counts
  return contentTriggers
    .filter((event) => isDateInInterval(event.created_at, period))
    .reduce<CollectContentDataReturn>(
      (acc, event) => {
        let collection: ContentDataCollection | undefined;

        switch (event.name) {
          case NAnalytics.EventNames.LinkTrigger:
            collection = acc.links;
            break;

          case NAnalytics.EventNames.VideoPlay:
            collection = acc.videos;
            break;

          case NAnalytics.EventNames.PodcastPlay:
            collection = acc.podcasts;
            break;
          default:
            console.error('Unexpected event name on event:', event);
        }

        if (collection) {
          collection.all.push(event);
          let urlList = collection[event.data.url];

          if (!urlList) {
            urlList = [];
            collection[event.data.url] = urlList;
          }

          urlList.push(event);
        }

        return acc;
      },
      {
        videos: { all: [] },
        links: { all: [] },
        podcasts: { all: [] },
      },
    );
}

export function prepareCSVDataFromDataLines<XType extends number | Date>(lines: DataLine<XType>[]) {
  return lines.map((line) =>
    line.points.reduce<Record<string, string | number>>(
      (acc, point) => {
        acc[point.x.toString()] = point.y;
        return acc;
      },
      {
        title: line.title,
      },
    ),
  );
}
