import React from 'react';
import PieChart from '../PieChart';
import DESIGN from '../../../constants/designConstants';
import { CollectContentDataReturn } from '../../../utilities/graphUtils';
import DownloadCSVButton from '../../base/DownloadCSVButton';

export interface TotalDownloadChartProps {
  data: CollectContentDataReturn;
}

const TotalDownloadChart = (props: TotalDownloadChartProps): JSX.Element => {
  const { data } = props;
  const { videos, links, podcasts } = data;
  const slices = [
    {
      title: 'Videos',
      colour: DESIGN.COLOR_BLUE,
      value: videos.all.length,
    },
    {
      title: 'Resources',
      colour: DESIGN.COLOR_GREEN,
      value: links.all.length,
    },
    {
      title: 'Podcasts',
      colour: DESIGN.COLOR_RED,
      value: podcasts.all.length,
    },
  ];

  const csvData = slices.map((slice) => ({
    title: slice.title,
    downloads: slice.value,
  }));

  return (
    <PieChart
      name="Total Downloads by type"
      category="Total"
      data={slices}
      actionButtons={[
        <DownloadCSVButton downloadData={csvData} fileName="Total Downloads by type" />,
      ]}
    />
  );
};

export default TotalDownloadChart;
