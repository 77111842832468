import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { useStyles, Order, RowData, ColumnData } from '../TableDefault';

interface TableDefaultHeadProps<Data extends RowData> {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: keyof Data;
  rowCount: number;
  columns: ColumnData<Data>[];
  actionsColumn: boolean;
  actionsColumnHeading?: string;
  viewDataOnly?: boolean | undefined;
}

function TableDefaultHead<Data extends RowData>(props: TableDefaultHeadProps<Data>) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    columns,
    actionsColumn,
    actionsColumnHeading,
    viewDataOnly,
  } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {!viewDataOnly && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all rows' }}
            />
          </TableCell>
        )}
        {columns.map((c) => (
          <TableCell
            key={c.id.toString()}
            align="left"
            padding={c.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === c.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === c.id}
              direction={orderBy === c.id ? order : 'asc'}
              onClick={createSortHandler(c.id)}
            >
              {c.label ?? c.id}
              {orderBy === c.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {actionsColumn && (
          <TableCell className={classes.actionColumn}>{actionsColumnHeading}</TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}

export default TableDefaultHead;
