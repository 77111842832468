import { useEffect } from 'react';
import trimTrailingSlash from '../trimTrailingSlash';

export interface MessageData {
  type: string;
}

export default function <T extends MessageData> (type: T['type'], handler: (data: T) => void) {
  const originUrl = trimTrailingSlash(window.location.href);

  function handleMessage(message: MessageEvent<T>) {
    if (trimTrailingSlash(message.origin) === originUrl) {
      if (message.data.type === type) {
        console.log(message);
        handler(message.data);
      } else {
        // console.log(`Message type rejected, should be ${type}:`, message.data.type);
      }
    } else {
      // console.error(`Message origin rejected, should be ${originUrl}:`,
      // message.origin, message.data);
    }
  }

  useEffect(() => {
    window.addEventListener('message', handleMessage, false);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  });
}
