import React from 'react';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import FilterListIcon from '@material-ui/icons/FilterList';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { CSVLink } from 'react-csv';
import SearchField from '../../SearchField';

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight: {
      color: theme.palette.secondary.main,
      backgroundColor: lighten(theme.palette.secondary.light, 0.85),
    },
    title: {
      flex: '1 1 100%',
    },
  }),
);

interface TableDefaultToolbarProps {
  numSelected: number;
  onClickFilter?: () => void;
  onClickDelete?: () => void;
  handleSearch?: () => void;
  handleClearSearch?: () => void;
  setSearchInputData?: (input:string) => void;
  searchInputData?: string;
  onClickAdd?: () => void;
  viewDataOnly?: boolean | undefined;
  hideFilterButton?: boolean;
  hideAddButton?: boolean;
  hideDownloadButton?: boolean;
  hideDeleteButton?: boolean;
  hideSearchButton?: boolean;
  csvData: object[];
  csvFile: string;
}

const TableDefaultToolbar = (props: TableDefaultToolbarProps) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    onClickFilter,
    onClickDelete,
    onClickAdd,
    viewDataOnly,
    hideDownloadButton,
    hideAddButton,
    hideDeleteButton,
    hideFilterButton,
    hideSearchButton,
    csvData,
    csvFile,
    handleSearch,
    handleClearSearch,
    setSearchInputData,
    searchInputData,
  } = props;

  // const handleSearchData = () => {
  //   // setInputData('');
  //   console.log({ inputData });
  // };

  return (
    <Toolbar className={classes.root}>
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div" />
      )}
      {numSelected > 0 && !hideDeleteButton ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete" onClick={onClickDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <div className="tool-tip-wrapper">
          {!hideSearchButton &&
          setSearchInputData &&
          handleClearSearch &&
          handleSearch && (
            <SearchField
              handleSearch={handleSearch}
              setSearchInputData={setSearchInputData}
              searchInputData={searchInputData}
              handleClearSearch={handleClearSearch}
            />
          )}
          {!hideDownloadButton && csvData && (
            <CSVLink data={csvData} filename={csvFile}>
              <Tooltip title="Download list">
                <IconButton aria-label="Download list" className="tool-tip-wrapper__button">
                  <p>Download</p>
                  <CloudDownloadOutlinedIcon style={{ marginLeft: '10px' }} />
                </IconButton>
              </Tooltip>
            </CSVLink>
          )}
          {!hideFilterButton && (
            <Tooltip title="Filter list">
              <IconButton
                aria-label="filter list"
                className="tool-tip-wrapper__button"
                onClick={onClickFilter}
              >
                <p>Filter</p>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          )}
          {!viewDataOnly && !hideAddButton && (
            <Tooltip title="Add">
              <IconButton
                aria-label="add item"
                className="tool-tip-wrapper__icon"
                onClick={onClickAdd}
              >
                <AddCircleIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      )}
    </Toolbar>
  );
};

export default TableDefaultToolbar;
