/* eslint-disable camelcase */

import { KeysOf } from './TransportTypes';

export namespace NAnalytics {
  export const EventGroups = {
    TimeSpent: 'TimeSpent',
    LoadTime: 'LoadTime',
    ContentTrigger: 'ContentTrigger',
    Feedback: 'Feedback',
    LobbyVideo: 'Lobby Video',
    AnginaAcademyVideo: 'Angina Academy Video',
    CardioConnectVideo: 'Cardio Connect Video',
    PodcastPercentageListened: 'Podcast Percentage Listened',
    VideoPercentageWatched: 'Video Percentage Watched',
    Login: 'Login',
    Delete: 'Delete',
  } as const;

  export const EventNames = {
    LinkTrigger: 'LinkTrigger',
    VideoPlay: 'VideoPlay',
    PodcastPlay: 'PodcastPlay',
    Feedback: 'Feedback',
    Experience: 'Experience',
    AnginaAcademy: 'Angina Academy',
    CardioConnect: 'CardioConnect',
    Lobby: 'Lobby',
    ProductRoom: 'Product Room',
    VisitorLogin: 'VisitorLogin',
  } as const;

  /**
   * Events arrive at the frontend with dates serialized as strings, so I've added the
   * `DateType` type param to account for this
   */
  export interface GenericAnalyticsEvent<Data extends Record<string, any>, DateType> {
    id: number;
    visitor_user_id: number;
    group: string;
    name: string;
    data: Data;
    created_at: DateType;
  }

  export type IAnalyticsEvent = GenericAnalyticsEvent<Record<string, any>, Date>;

  export interface ITimespentAggregateEventData {
    TotalHours: string;
    TotalMilliseconds: string;
    TotalMinutes: string;
    TotalSeconds: string;
  }

  export interface ITimespentAggregateEvent
    extends GenericAnalyticsEvent<ITimespentAggregateEventData, string> {
    finished_at: string;
  }

  export interface ILoadTimeEventData {
    seconds: number;
  }

  export interface ILoadTimeEvent
    extends GenericAnalyticsEvent<ILoadTimeEventData, string> {
    finished_at: string;
  }

  export interface IContentTriggerEventData {
    source: string;
    url: string;
  }

  export type IContentTriggerEvent = GenericAnalyticsEvent<IContentTriggerEventData, string>

  export interface IWatchPercentageEventData {
    SessionId: string;
    PercentageWatched: number;
  }

  export type IWatchPercentageEvent = GenericAnalyticsEvent<IWatchPercentageEventData, string>

  export interface IFeedbackEventData {
    name: string;
    subject: string;
    message: string;
  }
  export type IFeedbackEvent = GenericAnalyticsEvent<IFeedbackEventData, string>

  export interface ILoginEventData {
  }
  export type ILoginEvent = GenericAnalyticsEvent<ILoginEventData, string>

  export interface IDeleteEventData {
  }

  export type IDeleteEvent = GenericAnalyticsEvent<IDeleteEventData, string>

  export const AggregateOptions = {
    none: 'none',
    timespent: 'timespent',
  } as const;
  export type TAggregateOptions = KeysOf<typeof AggregateOptions>;

  export interface IAnalyticsQueryParams {
    aggregate?: TAggregateOptions;
    group?: string;
    name?: string;
    visitor_user_id?: number;
    data?: {
      field: string;
      value: string;
    }
    order?: {
      by: string;
      dir: 'asc' | 'desc'
    }
    page?: number;
    pageSize?: number;
    format?: 'json' | 'csv';
  }

  export namespace Get {
    export type Request = {};
    export type Response = {
      results: GenericAnalyticsEvent<Record<string, any>, string>[];
      total: number;
    };
    export type Params = IAnalyticsQueryParams;
  }

  export namespace Post {
    export type Request = {
      group: string;
      name: string;
      data: object | string;
    };
    export type Response = {};
    export type Params = {};
  }
}
