import addAuthHeader from '../utilities/auth';
import API from '../constants/apiConstants';
import handleResponse from '../helpers/handleResponse';
import { NAnalytics } from './transportTypes/NAnalytics';
import { isDateInInterval } from '../utilities/graphUtils';
import { AnalyticsTableData } from '../components/analytics/AnalyticsHistoryComponent';

const apiUrl = process.env.REACT_APP_API;

export interface IParams {
  [key: string]: string | number;
}

function buildQuerySegments(params: IParams, parent?: string): string[] {
  const queries: string[] = [];
  Object.keys(params).forEach((prop) => {
    if (Object.hasOwnProperty.call(params, prop)) {
      const value = params[prop];

      const tag = parent ? `${parent}[${prop}]` : prop;

      if (typeof value === 'object') {
        queries.push(...buildQuerySegments(value, tag));
      } else {
        queries.push(`${tag}=${value}`);
      }
    }
  });

  return queries;
}

function buildQueryString(params: IParams) {
  const queries = buildQuerySegments(params);

  const queryString = !queries.length ? '' : `?${queries.join('&')}`;

  // console.log('Query String:', queryString);

  return queryString;
}

function getTimeSpentData(): Promise<NAnalytics.ITimespentAggregateEvent[]> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  const queryString = buildQueryString({
    group: NAnalytics.EventGroups.TimeSpent,
  });
  return fetch(`${apiUrl}${API.ANALYTICS}${queryString}`, requestOptions)
    .then(handleResponse)
    .then(
      (response: NAnalytics.Get.Response) =>
        response.results as NAnalytics.ITimespentAggregateEvent[],
    );
}

function getLoadTimeData(): Promise<NAnalytics.ILoadTimeEvent[]> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  const queryString = buildQueryString({
    group: NAnalytics.EventGroups.LoadTime,
  });
  return fetch(`${apiUrl}${API.ANALYTICS}${queryString}`, requestOptions)
    .then(handleResponse)
    .then(
      (response: NAnalytics.Get.Response) =>
        response.results as NAnalytics.ILoadTimeEvent[],
    );
}

function getContentTriggerData(): Promise<NAnalytics.IContentTriggerEvent[]> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  const queryString = buildQueryString({
    group: NAnalytics.EventGroups.ContentTrigger,
  });
  return fetch(`${apiUrl}${API.ANALYTICS}${queryString}`, requestOptions)
    .then(handleResponse)
    .then(
      (response: NAnalytics.Get.Response) => response.results as NAnalytics.IContentTriggerEvent[],
    );
}

function getPodcastListenPercentData(): Promise<NAnalytics.IWatchPercentageEvent[]> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  const queryString = buildQueryString({
    group: NAnalytics.EventGroups.PodcastPercentageListened,
  });
  return fetch(`${apiUrl}${API.ANALYTICS}${queryString}`, requestOptions)
    .then(handleResponse)
    .then(
      (response: NAnalytics.Get.Response) => response.results as NAnalytics.IWatchPercentageEvent[],
    );
}

function getVideoWatchPercentData(): Promise<NAnalytics.IWatchPercentageEvent[]> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  const queryString = buildQueryString({
    group: NAnalytics.EventGroups.VideoPercentageWatched,
  });
  return fetch(`${apiUrl}${API.ANALYTICS}${queryString}`, requestOptions)
    .then(handleResponse)
    .then(
      (response: NAnalytics.Get.Response) => response.results as NAnalytics.IWatchPercentageEvent[],
    );
}

function getFeedbackData(): Promise<NAnalytics.IFeedbackEvent[]> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  const queryString = buildQueryString({
    group: NAnalytics.EventGroups.Feedback,
  });
  return fetch(`${apiUrl}${API.ANALYTICS}${queryString}`, requestOptions)
    .then(handleResponse)
    .then((response: NAnalytics.Get.Response) => response.results as NAnalytics.IFeedbackEvent[]);
}

function getLoginData(): Promise<NAnalytics.ILoginEvent[]> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  const queryString = buildQueryString({
    group: NAnalytics.EventGroups.Login,
  });
  return fetch(`${apiUrl}${API.ANALYTICS}${queryString}`, requestOptions)
    .then(handleResponse)
    .then((response: NAnalytics.Get.Response) => response.results as NAnalytics.ILoginEvent[]);
}

function getDeletedData(): Promise<NAnalytics.IDeleteEvent[]> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  const queryString = buildQueryString({
    group: NAnalytics.EventGroups.Delete,
  });
  return fetch(`${apiUrl}${API.ANALYTICS}${queryString}`, requestOptions)
    .then(handleResponse)
    .then((response: NAnalytics.Get.Response) => response.results as NAnalytics.IDeleteEvent[]);
}

function collectTimePerRoomData(
  userData: NAnalytics.ITimespentAggregateEvent[],
  period: Interval,
): AnalyticsTableData<number>[] {
  // Collect data into per-room aggregate
  const roomTimeMinutes = userData
    .filter(
      (event) =>
        isDateInInterval(event.created_at, period) || isDateInInterval(event.finished_at, period),
    )
    .reduce<AnalyticsTableData<number>[]>((acc, event) => {
      // Exclude the "total experience time" from the calculations
      if (event.name === NAnalytics.EventNames.Experience) {
        return acc;
      }

      const roomTotal = acc.find((r) => r.title === event.name);
      if (!roomTotal) {
        acc.push({
          id: event.name,
          title: event.name,
          data: Number.parseFloat(event.data.TotalMinutes),
        });
      } else {
        roomTotal.data! += Number.parseFloat(event.data.TotalMinutes);
      }
      return acc;
    }, [])
    .sort((a, b) => (b.data ?? 0) - (a.data ?? 0));

  // Calculate total time in rooms
  const totalTime = roomTimeMinutes.reduce((acc, room) => acc + (room.data ?? 0), 0);

  // Calculate time percentages
  return roomTimeMinutes.map((room) => ({
    ...room,
    percentage: ((room.data ?? 0) * 100) / totalTime, // percentage is 0-100 not 0-1
  }));
}

const service = {
  getTimeSpentData,
  getLoadTimeData,
  getContentTriggerData,
  getPodcastListenPercentData,
  getVideoWatchPercentData,
  getFeedbackData,
  getLoginData,
  collectTimePerRoomData,
  getDeletedData,
};

export default service;
