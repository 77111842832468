import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import UnauthorizedPage from '../containers/UnauthorizedPage';
import ButtonDefault from '../components/base/ButtonDefault';
import PasswordRequirements from '../components/PasswordRequirements';

export interface SetPasswordSuccessProps {

}

const SetPasswordSuccess = (props: SetPasswordSuccessProps): JSX.Element => (
  <UnauthorizedPage>
    <Grid className="set-password" container direction="column" item xs={12}>
      <h1 className="set-password__title">Success</h1>
      <p className="set-password__success">
        Your password has been set. You can now log into the experience using your new password.
      </p>
    </Grid>
  </UnauthorizedPage>
);

export default SetPasswordSuccess;
