import React, { ChangeEvent, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { Controller, useForm } from 'react-hook-form';
import { InputAdornment } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
      marginRight: '25px',
      borderRadius: '10px',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
      color: 'black',
    },
    divider: {
      height: 28,
      margin: 4,
    },
  }),
);

export interface SearchFieldProps {
  searchInputData: string | undefined;
  handleSearch: () => void;
  handleClearSearch: () => void;
  setSearchInputData: (input: string) => void;
}
export default function SearchField(props: SearchFieldProps) {
  const { searchInputData, handleSearch, setSearchInputData, handleClearSearch } = props;
  const classes = useStyles();
  const { control, setValue } = useForm();

  useEffect(() => {
    setValue('search', searchInputData);
    if (searchInputData &&
        searchInputData?.length > 0) setSearchInputData(searchInputData);
  }, [searchInputData]);

  const handleFieldChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const input = e.target.value;
    setSearchInputData(input);
  };

  return (
    <Paper className={`${classes.root} search-input-wrapper`}>
      <div className="search-field">
        <Controller
          name="search"
          control={control}
          defaultValue={searchInputData}
          render={({ field }) => (
            <InputBase
              className={classes.input}
              placeholder="Search by visitor name or Country"
              inputProps={{ 'aria-label': 'search' }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle"
                    onClick={handleClearSearch}
                    onMouseDown={handleClearSearch}
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              }
              {...field}
              onChange={handleFieldChange}
            />
          )}
        />
        <IconButton
          className={classes.iconButton}
          aria-label="search"
          onClick={handleSearch}
          onMouseDown={handleSearch}
        >
          <SearchIcon />
        </IconButton>
      </div>
    </Paper>
  );
}
