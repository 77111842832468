import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Button, DialogActions, Grid } from '@material-ui/core';
import { endOfDay, startOfDay } from 'date-fns';
import { setDataPeriodEnd, setDataPeriodStart } from '../../store/uiSlice';
import { useAppDispatch } from '../../store/store';

export interface DataRangeSettingsOverlayProps {
  open: boolean;
  period?: Partial<Interval>;
  onClose: () => void;
}

const DataRangeSettingsOverlay = (props: DataRangeSettingsOverlayProps): JSX.Element => {
  const { open, period, onClose } = props;

  const dispatch = useAppDispatch();

  const [tempStartDate, setTempStartDate] = useState<Date | null>(
    period?.start ? new Date(period.start) : null,
  );
  const [tempEndDate, setTempEndDate] = useState<Date | null>(
    period?.end ? new Date(period.end) : null,
  );

  useEffect(() => {
    setTempStartDate(period?.start ? new Date(period.start) : null);
    setTempEndDate(period?.end ? new Date(period.end) : null);
  }, [period, open]);

  const handleStartChange = (date: MaterialUiPickersDate, value: string | null | undefined) => {
    setTempStartDate(date && startOfDay(date));
  };

  const handleEndChange = (date: MaterialUiPickersDate, value: string | null | undefined) => {
    setTempEndDate(date && endOfDay(date));
  };

  const handleSave = () => {
    dispatch(setDataPeriodStart(tempStartDate));
    dispatch(setDataPeriodEnd(tempEndDate));
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Settings</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <KeyboardDatePicker
              disableToolbar
              clearable
              variant="inline"
              format="dd/MM/yyyy"
              label="Data Start"
              maxDate={tempEndDate ?? new Date()}
              value={tempStartDate}
              onChange={handleStartChange}
              KeyboardButtonProps={{
                'aria-label': 'Data Start',
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <KeyboardDatePicker
              disableToolbar
              clearable
              variant="inline"
              format="dd/MM/yyyy"
              label="Data End"
              minDate={tempStartDate ?? undefined}
              maxDate={new Date()}
              value={tempEndDate}
              onChange={handleEndChange}
              KeyboardButtonProps={{
                'aria-label': 'Data End',
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave}>Save</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DataRangeSettingsOverlay;
