import React, { useState, MouseEvent, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { ColumnData, RowData } from '../TableDefault';
import FilterOverlayRow from './FilterOverlayRow';
import { Key, KeyedElement } from '../../../utilities/frontendTypes';
import useNextKey from '../../../utilities/hooks/useNextKey';
import ButtonDefault from '../ButtonDefault';
import { Filter } from '../Filter';

export interface FilterOverlayProps<Data extends RowData> {
  open: boolean;
  columns: ColumnData<Data>[];
  filters: Filter<Data>[];
  onClose?: (event: object, reason: string) => void;
  onSave?: (filters: Filter<Data>[]) => void;
}

export type KeyedFilter<Data extends RowData> = Filter<Data> & KeyedElement;

const FilterOverlay = <Data extends RowData>(props: FilterOverlayProps<Data>): JSX.Element => {
  const { open, onClose, columns, filters, onSave } = props;

  const [filterState, setFilterState] = useState<KeyedFilter<Data>[]>([]);
  const { nextKey, reset } = useNextKey();

  useEffect(() => {
    const incoming = filters.map<KeyedFilter<Data>>((f, i) => ({ ...f, key: i }));
    setFilterState(incoming);
  }, [filters]);

  const handleFilterChange = (key?: Key) => (filter: Filter<Data>) => {
    const copy = [...filterState];
    if (key !== undefined) {
      const index = copy.findIndex((f) => f.key === key);
      copy[index] = {
        ...filter,
        key,
      };
    } else {
      copy.push({
        ...filter,
        key: nextKey(),
      });
    }
    setFilterState(copy);
  };

  const handleFilterDelete = (key: Key) => (filter: Filter<Data>) => {
    const copy = [...filterState];
    copy.splice(
      copy.findIndex((f) => f.key === key),
      1,
    );
    setFilterState(copy);
  };

  const handleSave = (e: MouseEvent<HTMLButtonElement>) => {
    if (onSave) {
      onSave(filterState);
    }
    if (onClose) {
      onClose(e, 'save');
    }
  };

  const handleCancel = (e: MouseEvent<HTMLButtonElement>) => {
    if (onClose) {
      onClose(e, 'cancel');
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Change Filters</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Field</TableCell>
              <TableCell>Operator</TableCell>
              <TableCell>Value</TableCell>
              <TableCell /> {/* Actions */}
            </TableRow>
          </TableHead>
          <TableBody>
            {filterState.map((f) => (
              <FilterOverlayRow
                key={f.key}
                filter={f}
                fieldOptions={columns}
                onChange={handleFilterChange(f.key)}
                onDelete={handleFilterDelete(f.key)}
              />
            ))}
            <FilterOverlayRow
              filter={{
                type: 'empty',
                field: null,
                operator: null,
                value: null,
              }}
              fieldOptions={columns}
              onChange={handleFilterChange()}
            />
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <ButtonDefault className="btn-medium btn-cancel" onClick={handleCancel}>
          Cancel
        </ButtonDefault>
        <ButtonDefault className="btn-medium btn-save" onClick={handleSave}>
          Save
        </ButtonDefault>
      </DialogActions>
    </Dialog>
  );
};

export default FilterOverlay;
