import React from 'react';
import { Grid } from '@material-ui/core';
import AnalyticsHistoryComponent, {
  AnalyticsHistoryComponentProps,
  AnalyticsTableData,
} from './AnalyticsHistoryComponent';
import { GRAPH_TYPES } from '../../constants/analyticsConstants';

export interface LoginHistoryAnalyticsProps
  extends Omit<AnalyticsHistoryComponentProps<number>, 'type'> {}

const EventCountAnalyticsPanel = (props: LoginHistoryAnalyticsProps): JSX.Element => {
  const { name, category, data, csvData, formatter } = props;

  // To display when login data is empty
  const userLoginDummyData = [
    {
      id: 1,
      title: 'No data to show',
      data: 0,
    },
    {
      id: 2,
      title: 'No data to show',
      data: 0,
    },
    {
      id: 3,
      title: 'No data to show',
      data: 0,
    },
    {
      id: 4,
      title: 'No data to show',
      data: 0,
    },
  ] as AnalyticsTableData<number>[];

  return (
    <Grid container item xs={12} sm={12} md={12} lg={12}>
      {data && (
        <AnalyticsHistoryComponent<number>
          name={name}
          category={category}
          type={GRAPH_TYPES.EVENT_COUNT}
          data={data?.length > 0 ? data : userLoginDummyData}
          csvData={csvData}
          formatter={formatter}
        />
      )}
    </Grid>
  );
};

export default EventCountAnalyticsPanel;
