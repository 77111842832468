import { parse } from 'qs';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import logo from '../assets/images/MenariniLogo.jpg';
import ZoomClientView from '../components/ZoomClientView';

const ZoomAuditoriumView = (): JSX.Element => {
  const location = useLocation();
  const query = parse(location.search, { ignoreQueryPrefix: true });

  const meetingId = query.meeting as string;
  const password = query.password as string;
  const name = query.name as string;
  const email = query.email as string;
  const tk = query.tk as string | undefined;

  const [isFullscreen, setIsFullscreen] = useState(false);

  // Apply classname to document body to enable zoom overrides
  useEffect(() => {
    const classes = ['zoom-auditorium'];
    if (isFullscreen) {
      classes.push('zoom-auditorium--fullscreen');
    }

    // switch (background) {
    //   case 1:
    //     classes.push('opt-1');
    //     break;
    //   case 2:
    //     classes.push('opt-2');
    //     break;
    //   default:
    //     console.error('Unknown background selected:', background);
    // }

    document.body.classList.add(...classes);
    return () => {
      document.body.classList.remove(...classes);
    };
  });

  // Listen for fullscreen click events
  useEffect(() => {
    function fullscreenChanged() {
      // document.fullscreenElement will point to the element that
      // is in fullscreen mode if there is one. If there isn't one,
      // the value of the property is null.
      const hasFSElement = !!document.fullscreenElement;
      if (hasFSElement) {
        console.log('Element entered fullscreen mode:', document.fullscreenElement);
      } else {
        console.log('Leaving fullscreen mode.');
      }

      setIsFullscreen(hasFSElement);
    }

    document.addEventListener('fullscreenchange', fullscreenChanged);
    return () => {
      document.removeEventListener('fullscreenchange', fullscreenChanged);
    };
  });

  // Update waiting room message
  useEffect(() => {
    const waitingRoomText = document.getElementsByClassName('wr-default__text');
    if (waitingRoomText && waitingRoomText.length > 0) {
      waitingRoomText[0].innerHTML = 'Please wait, the Webinar host will let you in soon.';
    }
  });

  useEffect(() => {
    document.documentElement.style.overflow = 'auto';
    document.documentElement.style.overflowX = 'auto';
    document.documentElement.style.overflowY = 'auto';
    document.documentElement.style.minWidth = 'unset';
  });

  return (
    <div>
      <img src={logo} alt="Logo" className="menarini-logo" />
      <div className="banner-group">
        <div className="vertical-banner">
          {/* <h1 className="vertical-banner__text">Banner</h1> */}
        </div>
        <div className="horizontal-banner">
          {/* <h1 className="horizontal-banner__text">Event Name</h1> */}
        </div>
      </div>
      <ZoomClientView meetingId={meetingId} password={password} email={email} name={name} tk={tk} />
    </div>
  );
};
export default ZoomAuditoriumView;
