import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import ReactGA from 'react-ga4';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import store from './store/store';
import App from './containers/App';
import reportWebVitals from './reportWebVitals';
import './sass/app.scss';

const persistor = persistStore(store, null, () => {
  store.getState();
});

const clientId = process.env.REACT_APP_CLIENT_ID!;
ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID ?? '');

ReactDOM.render(
  // https://reactjs.org/docs/strict-mode.html
  <React.StrictMode>
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <PersistGate persistor={persistor}>
          <GoogleOAuthProvider clientId={clientId}>
            <App />
          </GoogleOAuthProvider>
        </PersistGate>
      </MuiPickersUtilsProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
