import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  IAnalyticsBasicData,
  IAnalyticsTable,
  IAnalyticsUniqueLogins,
  IAnalyticsUniqueLoginsData,
  ILoginHistoryData,
  UserBrowserData,
} from '../utilities/backendTypes';
import { logout } from './userSlice';
import analyticsService from '../service/analyticsService';
import { NAnalytics } from '../service/transportTypes/NAnalytics';
import store, { isTokenExpired } from './store';

export interface IAnalyticsState {
  loginData: NAnalytics.ILoginEvent[];
  timeSpent: NAnalytics.ITimespentAggregateEvent[];
  loadTime: NAnalytics.ILoadTimeEvent[];
  feedback: NAnalytics.IFeedbackEvent[];
  contentTrigger: NAnalytics.IContentTriggerEvent[];
  deletedData: NAnalytics.IDeleteEvent[];
  podcastListenPercent: NAnalytics.IWatchPercentageEvent[];
  videoWatchPercent: NAnalytics.IWatchPercentageEvent[];
  message: string | undefined;
}

export const initialState: IAnalyticsState = {
  // summaryButtons: [],
  timeSpent: [],
  loadTime: [],
  contentTrigger: [],
  podcastListenPercent: [],
  videoWatchPercent: [],
  loginData: [],
  deletedData: [],
  feedback: [],
  message: '',
};

export const getTimeSpentData = createAsyncThunk(
  'getTimeSpentData',
  analyticsService.getTimeSpentData,
);

export const getLoadTimeData = createAsyncThunk(
  'getLoadTimeData',
  analyticsService.getLoadTimeData,
);

export const getContentTriggerData = createAsyncThunk(
  'getContentTriggerData',
  analyticsService.getContentTriggerData,
);

export const getPodcastListenPercentData = createAsyncThunk(
  'getPodcastListenPercentData',
  analyticsService.getPodcastListenPercentData,
);

export const getVideoWatchPercentData = createAsyncThunk(
  'getVideoWatchPercentData',
  analyticsService.getVideoWatchPercentData,
);

export const getFeedbackData = createAsyncThunk(
  'getFeedbackData',
  analyticsService.getFeedbackData,
);

export const getDeletedData = createAsyncThunk(
  'getDeletedData',
  analyticsService.getDeletedData,
);
export const getLoginData = createAsyncThunk(
  'getLoginData',
  analyticsService.getLoginData,
);

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(logout.type, () => initialState)

      .addCase(getDeletedData.pending, (state) => {
        state.deletedData = [];
      })
      .addCase(getDeletedData.fulfilled, (state, action) => {
        state.deletedData = action.payload;
      })
      .addCase(getDeletedData.rejected, (state, action) => {
        state.deletedData = [];
        if (!isTokenExpired(action.error.message ?? '')) {
          state.message = action.error.message;
        }
      })
      .addCase(getTimeSpentData.pending, (state) => {
        state.timeSpent = [];
      })
      .addCase(getTimeSpentData.fulfilled, (state, action) => {
        state.timeSpent = action.payload;
      })
      .addCase(getTimeSpentData.rejected, (state, action) => {
        state.timeSpent = [];
        if (!isTokenExpired(action.error.message ?? '')) {
          state.message = action.error.message;
        }
      })
      .addCase(getLoadTimeData.pending, (state) => {
        state.loadTime = [];
      })
      .addCase(getLoadTimeData.fulfilled, (state, action) => {
        state.loadTime = action.payload;
      })
      .addCase(getLoadTimeData.rejected, (state, action) => {
        state.loadTime = [];
        if (!isTokenExpired(action.error.message ?? '')) {
          state.message = action.error.message;
        }
      })
      .addCase(getContentTriggerData.fulfilled, (state, action) => {
        state.contentTrigger = action.payload;
      })
      .addCase(getContentTriggerData.rejected, (state, action) => {
        if (!isTokenExpired(action.error.message ?? '')) {
          state.message = action.error.message;
        }
      })
      .addCase(getPodcastListenPercentData.fulfilled, (state, action) => {
        state.podcastListenPercent = action.payload;
      })
      .addCase(getPodcastListenPercentData.rejected, (state, action) => {
        if (!isTokenExpired(action.error.message ?? '')) {
          state.message = action.error.message;
        }
      })
      .addCase(getVideoWatchPercentData.fulfilled, (state, action) => {
        state.videoWatchPercent = action.payload;
      })
      .addCase(getVideoWatchPercentData.rejected, (state, action) => {
        if (!isTokenExpired(action.error.message ?? '')) {
          state.message = action.error.message;
        }
      })
      .addCase(getFeedbackData.fulfilled, (state, action) => {
        state.feedback = action.payload;
      })
      .addCase(getFeedbackData.rejected, (state, action) => {
        if (!isTokenExpired(action.error.message ?? '')) {
          state.message = action.error.message;
        }
      })
      .addCase(getLoginData.pending, (state) => {
        state.loginData = [];
      })
      .addCase(getLoginData.fulfilled, (state, action) => {
        state.loginData = action.payload;
      })
      .addCase(getLoginData.rejected, (state, action) => {
        if (!isTokenExpired(action.error.message ?? '')) {
          state.message = action.error.message;
        }
      });
  },
});

export default analyticsSlice.reducer;
