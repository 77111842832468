import React from 'react';
import { VictoryPie } from 'victory';
import { Grid } from '@material-ui/core';
import GraphHeader, { GraphHeaderProps } from './GraphHeader';
import DESIGN from '../../constants/designConstants';

export interface DataSlice {
  title: string;
  colour: string;
  value: number;
}

interface PieChartProps {
  name?: string;
  category?: string;
  data: DataSlice[];
  actionButtons?: GraphHeaderProps['actionButtons'];
}

const PieChart = (props: PieChartProps): JSX.Element => {
  const { name = 'Pie Chart', category = 'Sample', data, actionButtons } = props;

  const slices = data
    .filter((s) => s.value > 0)
    .map((s) => ({ ...s, label: `${s.title}: ${s.value}` }));

  if (slices.length === 0) {
    slices.push({
      title: 'No Data',
      label: 'No Data',
      value: 1,
      colour: DESIGN.COLOR_GREY,
    });
  }

  return (
    <Grid className="pie-chart" container justifyContent="space-between" alignItems="center">
      <Grid container item xs={12}>
        <GraphHeader name={name} category={category} actionButtons={actionButtons} />
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <VictoryPie
          data={slices}
          height={230}
          colorScale={slices.map((slice) => slice.colour)}
          style={{
            data: {
              stroke: '#fff',
              strokeWidth: 3,
            },
          }}
          labels={slices.map((slice) => slice.label)}
          x="title"
          y="value"
        />
      </Grid>
    </Grid>
  );
};

export default PieChart;
