// Menu constants
const DESIGN = {
  COLOR_GREEN_BUTTON: '#0DA768',
  COLOR_GREEN_ARROW: '#B4EBCC',
  COLOR_GREEN: '#3EE1AA',
  COLOR_LIGHT_GREEN: '#D8F9EE',
  COLOR_YELLOW: '#F9AA31',
  COLOR_RED: '#ea4969',
  COLOR_LIGHT_RED: '#ec302433',
  COLOR_WHITE: '#FFF',
  COLOR_PINK: '#EC008C',
  COLOR_ICON_LIGHT_BLUE: '#BEDBFF',
  COLOR_BUTTON_LIGHT_BLUE: '#2CACE3',
  COLOR_DARK_BLUE: '#214882',
  COLOR_GREY: '#A7A7A7',
  COLOR_PALE_BROWN: '#C19A69',
  COLOR_BLUE: '#22B0FC',
  COLOR_LIGHT_BLUE: '#22b0fc38',
  GRAPH_RED: '#ea4969',
  GRAPH_GREEN: '#3CBA94',
  GRAPH_LIGHT_BLUE: '#00A6DB',
  GRAPH_DARK_BLUE: '#2480ED',
  MENARINI_BLUE: '#4894C3',
  MENARINI_RED: '#E5342C',
  MENARINI_ORANGE: '#F36D28',
  MENARINI_PINK: '#E30D86',
  MENARINI_YELLOW: '#D8AC29',
  MENARINI_DARK_BLUE: '#282A73',
};

export default DESIGN;
