import React, { ReactNode } from 'react';
import { Grid } from '@material-ui/core';
import DownloadCSVButton from '../base/DownloadCSVButton';
import { CSVFile } from '../../utilities/frontendTypes';

export interface GraphHeaderProps {
  name: string;
  category: string;
  actionButtons?: ReactNode[];
}

const GraphHeader = (props: GraphHeaderProps): JSX.Element => {
  const { name, category, actionButtons } = props;

  return (
    <Grid className="graph-header" container justifyContent="space-between" wrap="nowrap">
      <Grid className="graph-header__header" item>
        <p className="graph-header__title">{name}</p>
        <p className="graph-header__category">Category: {category}</p>
      </Grid>
      <Grid className="graph-header__actions" container item wrap="nowrap" spacing={2} direction="row" justifyContent="flex-end">
        {actionButtons?.map((child, index) => (
          <Grid item key={index}>
            {child}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default GraphHeader;
