import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import AuthorizedPage from '../containers/AuthorizedPage';
import PageHeader from '../components/base/PageHeader';
import { useAppDispatch, useAppSelector } from '../store/store';
import { loadVisitors } from '../store/userSlice';
import EventCountAnalyticsPanel from '../components/analytics/EventCountAnalyticsPanel';
import { getContentTriggerData, getDeletedData, getLoginData, getTimeSpentData } from '../store/analyticsSlice';
import UserRegistrationChart from '../components/analytics/charts/UserRegistrationChart';
import TotalDownloadChart from '../components/analytics/charts/TotalDownloadChart';
import { collectContentData, ContentDataCollection, isDateInInterval } from '../utilities/graphUtils';
import DataRangeSettingsOverlay from '../components/overlays/DataRangeSettingsOverlay';
import useDataPeriodInterval from '../helpers/hooks/useDataPeriodInterval';
import SettingsButton from '../components/base/SettingsButton';
import { AnalyticsTableData } from '../components/analytics/AnalyticsHistoryComponent';

function summariseDataCollection(
  data: ContentDataCollection,
  period: Interval,
): AnalyticsTableData<number>[] {
  return Object.entries(data)
    .filter(([key, events]) => key !== 'all')
    .map<AnalyticsTableData<number>>(([key, events]) => ({
      id: key,
      title: events[0].data.url,
      data: events.filter((event) => isDateInInterval(event.created_at, period)).length,
    }))
    .sort((a, b) => b.data - a.data);
}

const AnalyticsOverview = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const { tokenVerified, visitors } = useAppSelector((state) => state.user);
  const { contentTrigger, loginData, deletedData } = useAppSelector((state) => state.analytics);

  const [settingsOpen, setSettingsOpen] = useState(false);
  const period = useDataPeriodInterval();

  const contentData = collectContentData(contentTrigger, period);

  const linkData = summariseDataCollection(contentData.links, period);
  const videoData = summariseDataCollection(contentData.videos, period);
  const podcastData = summariseDataCollection(contentData.podcasts, period);

  useEffect(() => {
    if (tokenVerified) {
      dispatch(loadVisitors());
      dispatch(getTimeSpentData());
      dispatch(getContentTriggerData());
      dispatch(getLoginData());
      dispatch(getDeletedData());
    }
  }, [tokenVerified]);

  return (
    <AuthorizedPage>
      <PageHeader
        title="Analytics"
        hintMessage="View analytics summaries"
        actions={[<SettingsButton onClick={() => setSettingsOpen(true)} />]}
      />
      <Grid className="dashboard-body">
        <Grid container item xs={12} spacing={2} alignItems="stretch">
          <Grid container alignItems="stretch" item xs={12} sm={12} md={8}>
            <UserRegistrationChart
              period={period}
              visitors={visitors}
              loginEvents={loginData}
              deletionEvents={deletedData}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={4}>
            <TotalDownloadChart data={contentData} />
          </Grid>
          <Grid item xs={12}>
            <EventCountAnalyticsPanel
              name="Total Downloads - Resources"
              category="Total"
              data={linkData}
              csvData={linkData.map(({ title, data }) => ({
                title,
                downloads: data,
              }))}
            />
          </Grid>
          <Grid item xs={12}>
            <EventCountAnalyticsPanel
              name="Total Videos Played"
              category="Total"
              data={videoData}
              csvData={videoData.map(({ title, data }) => ({
                title,
                views: data,
              }))}
            />
          </Grid>
          <Grid container item xs={12}>
            <EventCountAnalyticsPanel
              name="Total Podcasts Played"
              category="Total"
              data={podcastData}
              csvData={podcastData.map(({ title, data }) => ({
                title,
                streams: data,
              }))}
            />
          </Grid>
        </Grid>
      </Grid>
      <DataRangeSettingsOverlay
        open={settingsOpen}
        period={period}
        onClose={() => setSettingsOpen(false)}
      />
    </AuthorizedPage>
  );
};

export default AnalyticsOverview;
