import { endOfToday, startOfToday, subDays } from 'date-fns';
import { useMemo } from 'react';
import { useAppSelector } from '../../store/store';

export default function () {
  const { dataPeriodStart, dataPeriodEnd } = useAppSelector((state) => state.uiData);
  const placeholderPeriod = {
    start: subDays(startOfToday(), 20),
    end: endOfToday(),
  };

  const period = useMemo<Interval>(
    () => ({
      start: dataPeriodStart ? new Date(dataPeriodStart) : placeholderPeriod.start,
      end: dataPeriodEnd ? new Date(dataPeriodEnd) : placeholderPeriod.end,
    }),
    [dataPeriodStart, dataPeriodEnd],
  );

  return period;
}
