import React from 'react';
import { NavLink, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useDispatch } from 'react-redux';
import PeopleIcon from '@material-ui/icons/People';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import TimelineIcon from '@material-ui/icons/Timeline';
import StoreIcon from '@material-ui/icons/Store';
import { parse } from 'qs';
import PATHS from '../constants/pathConstants';
import { logout } from '../store/userSlice';
import logo from '../assets/images/logo.png';

export interface SideNavProps {}

const SideNav = (props: SideNavProps): JSX.Element => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const routeMatch = useRouteMatch();
  let rootToken = PATHS.ROOT;

  const token = localStorage.getItem('token');
  if (token) {
    rootToken = `${rootToken}?token=${token}`;
  }

  const handleLogout = () => {
    dispatch(logout());
    history.push(PATHS.ADMIN);
    // if we don't reload the page, it doesn't show the Gmail data
    window.location.reload();
  };

  const query = parse(location.search, { ignoreQueryPrefix: true });
  const userId = Number.parseInt(query.user as string);

  return (
    <div className="side-nav">
      <Grid className="side-nav__authorized">
        <Grid className="side-nav__logo">
          <img width="100%" src={logo} alt="logo" />
        </Grid>
        <Grid className="side-nav__menu-container">
          <NavLink exact className="side-nav__menu" to={PATHS.HOME}>
            <HomeIcon />
            <p className="side-nav__menu-text">Home</p>
          </NavLink>
          <NavLink exact className="side-nav__menu" to={PATHS.USERS}>
            <PeopleIcon />
            <p className="side-nav__menu-text">Users</p>
          </NavLink>
          {routeMatch.path === PATHS.USER_DATA &&
          <div>
            <NavLink className="side-nav__menu side-nav__menu--sub" to={`${PATHS.USER_DATA}?user=${userId}`}>
              <AccountBoxIcon />
              <p className="side-nav__menu-text">User data</p>
            </NavLink>
          </div>
          }
          <NavLink className="side-nav__menu" to={PATHS.ANALYTICS}>
            <TimelineIcon />
            <p className="side-nav__menu-text">Analytics</p>
          </NavLink>
          <NavLink exact className="side-nav__menu" to={rootToken} target="_blank" rel="noopener noreferrer">
            <StoreIcon />
            <p className="side-nav__menu-text">Virtual Experience</p>
          </NavLink>
        </Grid>
        <Grid className="page-header__logout-btn">
          <span
            className="side-nav__menu"
            onClick={handleLogout}
            role="button"
            aria-hidden="true"
          >
            <ExitToAppIcon />
            <p className="side-nav__menu-text">Logout</p>
          </span>
        </Grid>
      </Grid>
    </div>
  );
};

export default SideNav;
