import React, { ReactNode } from 'react';
import { VictoryChart, VictoryLegend, VictoryLine, VictoryTheme } from 'victory';
import { Grid } from '@material-ui/core';
import GraphHeader, { GraphHeaderProps } from './GraphHeader';

export interface DataPoint<XType extends number | Date> {
  x: XType;
  y: number;
}

export interface DataLine<XType extends number | Date> {
  title: string;
  colour: string;
  points: DataPoint<XType>[];
}

export interface LineChartProps<XType extends number | Date> {
  name?: string;
  category?: string;
  data: DataLine<XType>[];
  actionButtons?: GraphHeaderProps['actionButtons'];
}

const LineChart = <XType extends number | Date>(props: LineChartProps<XType>): JSX.Element => {
  const { name = 'Line Chart', category = 'Sample', data, actionButtons } = props;

  const yMinMax = data
    .flatMap((line) => line.points) // collect all the points
    .map((point) => point.y) // extract y value
    .reduce<[number, number]>(
      (minMax, y) => {
        if (y < minMax[0]) {
          // find min of data set
          return [y, minMax[1]];
        }
        if (y > minMax[1]) {
          // find max of data set
          return [minMax[0], y];
        }
        return minMax;
      },
      [0, 10], // sensible default range to stop showing tiny floating points
    );

  return (
    <Grid className="line-chart" container item justify="space-between" alignItems="center">
      <Grid item xs={12}>
        <GraphHeader
          name={name}
          category={category}
          actionButtons={actionButtons}
        />
      </Grid>
      <Grid item xs={12}>
        <VictoryChart
          animate={{
            duration: 1000,
            onLoad: { duration: 1000 },
          }}
          scale={{ x: 'time', y: 'linear' }}
          theme={VictoryTheme.material}
          height={230}
          width={650}
          domain={{ y: yMinMax }}
        >
          <VictoryLegend
            x={190}
            y={10}
            data={data.map((e) => ({
              name: e.title,
              symbol: {
                fill: e.colour,
              },
            }))}
            centerTitle
            orientation="horizontal"
          />
          {data.map((line) => (
            <VictoryLine
              style={{
                data: { stroke: line.colour },
                parent: { border: '1px solid #ccc' },
              }}
              data={line.points}
            />
          ))}
        </VictoryChart>
      </Grid>
    </Grid>
  );
};

export default LineChart;
