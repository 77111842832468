import React, { ChangeEvent, useEffect, useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { Redirect, useLocation } from 'react-router-dom';
import { parse } from 'qs';
import { useDispatch, useSelector } from 'react-redux';
import ButtonDefault from '../components/base/ButtonDefault';
import UnauthorizedPage from '../containers/UnauthorizedPage';
import { resetPassword } from '../store/userSlice';
import CustomSnackbar from '../components/base/CustomSnackbar';
import { RootState } from '../store/store';
import MESSAGES from '../constants/messagesConstants';
import PasswordRequirements, { PasswordRequirementItem } from '../components/PasswordRequirements';
import PATHS from '../constants/pathConstants';
import useSnackbarMessage from '../utilities/hooks/useSnackbarMessage';

export interface SetPasswordData {
  password: string;
  confirmPassword: string;
}

const capitalRegex = /[A-Z]/;
const numberRegex = /[0-9]/;
const symbolRegex = /[^a-zA-Z0-9]/;
const whitespaceRegex = /^\S.+\S$/;

const SetPassword = (): JSX.Element => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { message, passwordSet } = useSelector((state: RootState) => state.user);

  const params = parse(location.search, { ignoreQueryPrefix: true });

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [ErrorMessage, setErrorMessage] = useSnackbarMessage({ message, severity: 'error' });

  let token: string;
  if (params && params.token) {
    token = params.token as string;
  }

  const passwordRequirements: PasswordRequirementItem[] = [
    {
      label: 'Contain at least 8 characters and no more than 64 characters',
      satisfied: password.length >= 8 && password.length <= 64,
      key: 'length',
    },
    {
      label: 'Include at least 1 upper case character',
      satisfied: password.match(capitalRegex) !== null,
      key: 'upper',
    },
    {
      label: 'Include at least 1 number',
      satisfied: password.match(numberRegex) !== null,
      key: 'numeric',
    },
    {
      label: 'Include at least 1 symbol',
      satisfied: password.match(symbolRegex) !== null,
      key: 'symbol',
    },
    {
      label: 'Not start or end with whitespace',
      satisfied: password.match(whitespaceRegex) !== null,
      key: 'trimmed',
    },
    {
      label: 'Passwords must match',
      satisfied: !!confirmPassword && password === confirmPassword,
      key: 'matching',
    },
  ];

  useEffect(() => setErrorMessage(message ?? ''), [message]);

  const onSubmit = () => {
    if (passwordRequirements.every((r) => r.satisfied) && token) {
      dispatch(resetPassword({
        password,
        token,
      }));
    }
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };

  if (passwordSet) {
    return (
      <Redirect to={PATHS.SET_PASSWORD_SUCCESS} push />
    );
  }

  return (
    <UnauthorizedPage>
      <Grid className="set-password" container direction="column" item xs={12}>
        <h1 className="set-password__title">Set password</h1>

        <Grid container direction="row" spacing={5}>
          <Grid container direction="column" item sm={6} xs={12} alignItems="flex-end">
            <TextField
              variant="outlined"
              label="Password"
              type="password"
              className="set-password__input"
              value={password}
              onChange={handlePasswordChange}
            />
            <TextField
              variant="outlined"
              label="Confirm Password"
              type="password"
              className="set-password__input"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
            <ButtonDefault
              className="set-password__button"
              onClick={onSubmit}
              large
              disabled={passwordRequirements.some((r) => !r.satisfied)}
            >
              CONFIRM
            </ButtonDefault>
          </Grid>
          <Grid container item sm={6} xs={12}>
            <PasswordRequirements requirements={passwordRequirements} />
          </Grid>
        </Grid>
      </Grid>
      {ErrorMessage}
    </UnauthorizedPage>
  );
};

export default SetPassword;
