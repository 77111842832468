import addAuthHeader from '../utilities/auth';
import handleResponse from '../helpers/handleResponse';
import API from '../constants/apiConstants';
import { IVisitor } from '../utilities/backendTypes';
import { NVisitor } from './transportTypes/NVisitor';

const apiUrl = process.env.REACT_APP_API;

interface forgotPasswordArgs {
  email: string;
}
function forgotPassword({ email }: forgotPasswordArgs): Promise<IVisitor> {
  const requestOptions: RequestInit = {
    method: 'POST',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
    body: JSON.stringify({
      email,
    }),
  };

  return fetch(`${apiUrl}${API.FORGOT_PASSWORD}`, requestOptions).then(handleResponse);
}

function resetPassword({ password, token }: NVisitor.Reset.Token.Post.Request & {token: string}):
  Promise<NVisitor.Reset.Token.Post.Response> {
  const requestOptions = {
    method: 'POST',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
    body: JSON.stringify({
      password,
    }),
  };
  return fetch(`${apiUrl}${API.RESET_PASSWORD}/${token}`, requestOptions).then(handleResponse);
}

function requestZoomSignature(meeting: NVisitor.Zoom.Post.Request):
  Promise<NVisitor.Zoom.Post.Response> {
  const requestOptions = {
    method: 'POST',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
    body: JSON.stringify({
      meetingId: meeting.number,
      role: 0,
    }),
  };
  return fetch(`${apiUrl}${API.ZOOM}`, requestOptions).then(handleResponse);
}

function convertVisitor(user: NVisitor.IVisitorSummary): IVisitor {
  const { metadata, ...others } = user;
  return {
    ...others,
    ...metadata,
  };
}

function loadVisitors():
  Promise<IVisitor[]> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  return fetch(`${apiUrl}${API.VISITOR}`, requestOptions)
    .then(handleResponse)
    .then((resp: NVisitor.Get.Response) => resp.users.map(convertVisitor));
}

const userService = {
  forgotPassword,
  resetPassword,
  loadVisitors,
  requestZoomSignature,
};
export default userService;
