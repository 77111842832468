import addAuthHeader from '../utilities/auth';
import handleResponse from '../helpers/handleResponse';
import API from '../constants/apiConstants';

const apiUrl = process.env.REACT_APP_API;

export function verifyLogin() {
  const requestOptions = {
    method: 'POST',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  return fetch(`${apiUrl}${API.ADMIN}`, requestOptions).then(handleResponse);
}

export default {
  verifyLogin,
};
