import React from 'react';
import PercentageListAnalyticsPanel from '../PercentageListAnalyticsPanel';
import analyticsService from '../../../service/analyticsService';
import { useAppSelector } from '../../../store/store';
import useDataPeriodInterval from '../../../helpers/hooks/useDataPeriodInterval';
import { NAnalytics } from '../../../service/transportTypes/NAnalytics';

export interface TimePerRoomChartProps {
  events: NAnalytics.ITimespentAggregateEvent[];
}

const TimePerRoomChart = (props: TimePerRoomChartProps): JSX.Element => {
  const { events } = props;

  const period = useDataPeriodInterval();
  const roomTimeData = analyticsService.collectTimePerRoomData(events, period);

  return (
    <PercentageListAnalyticsPanel
      name="Time per Room - Minutes"
      category="User"
      data={roomTimeData}
      csvFile="Time per Room Minutes"
      csvData={roomTimeData.map((event) => ({
        roomName: event.title,
        'time spent (mins)': event.data ?? 0,
        percentage: event.percentage ?? 0,
      }))}
    />
  );
};

export default TimePerRoomChart;
