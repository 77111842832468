export function copyTo<T>(from: Partial<T>, to: T) {
  return {
    ...to,
    ...from,
  };
}

export function patchList<T>(
  list: T[],
  update: Partial<T> | ((item: T) => Partial<T>),
  place: number | ((entry: T) => boolean),
  defaults: T,
) {
  const copy = [...list];

  // Find the index
  let index = -1;
  if (typeof place === 'number' && place >= 0 && place < list.length) {
    index = place;
  } else if (typeof place === 'function' && copy.find(place)) {
    index = copy.findIndex(place);
  }

  // Get the object to update
  let value: T = defaults;
  if (index >= 0) {
    value = copy[index];
  }

  // Update the object
  let changed: T | undefined;
  if (typeof update === 'function') {
    changed = copyTo(update(value), value);
  } else {
    changed = copyTo(update, value);
  }

  // Replace the original object with the changed one, or insert if no original
  if (index >= 0) {
    copy[index] = changed;
  } else {
    copy.push(changed);
  }

  return copy;
}

export function arrayDiff<T>(current: T[], upcoming: T[]) {
  const added: T[] = [];
  const removed = current.slice();

  upcoming.forEach((service) => {
    const index = removed.indexOf(service);
    if (index >= 0) {
      removed.splice(index, 1);
    } else if (!added.includes(service)) {
      added.push(service);
    }
  });

  return {
    added,
    removed,
  };
}

export function formatDate(date: Date) {
  return date.toLocaleString('en-AU');
}

export function formatDateFromString(dateStr: string) {
  return formatDate(new Date(dateStr));
}

export function notEmpty<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  return value !== null && value !== undefined;
}

export const getQueryStringValue = (key: string) => decodeURIComponent(window.location.search.replace(new RegExp(`^(?:.*[&\\?]${encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&')}(?:\\=([^&]*))?)?.*$`, 'i'), '$1'));

export default {
  copyTo,
  patchList,
  arrayDiff,
};
