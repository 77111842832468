import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert, Color } from '@material-ui/lab';

export interface CustomSnackbarProps {
  severity: Color;
  message: string;
  open: boolean;
  handleClose?: () => void;
}
export default function CustomSnackbar(props: CustomSnackbarProps) {
  const { severity, message, handleClose, open } = props;

  /*
  <Alert severity="error">This is an error message!</Alert>
  <Alert severity="warning">This is a warning message!</Alert>
  <Alert severity="info">This is an information message!</Alert>
  <Alert severity="success">This is a success message!</Alert>
   */

  return (
    <div>
      <Snackbar
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        autoHideDuration={6000}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
