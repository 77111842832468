import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Redirect, useLocation } from 'react-router-dom';
import { parse } from 'qs';
import AuthorizedPage from '../containers/AuthorizedPage';
import PageHeader from '../components/base/PageHeader';
import { useAppDispatch, useAppSelector } from '../store/store';
import PercentageListAnalyticsPanel from '../components/analytics/PercentageListAnalyticsPanel';
import { loadVisitors } from '../store/userSlice';
import { IVisitorDetails } from '../utilities/backendTypes';
import EventCountAnalyticsPanel from '../components/analytics/EventCountAnalyticsPanel';
import {
  getContentTriggerData,
  getPodcastListenPercentData,
  getTimeSpentData,
  getVideoWatchPercentData,
} from '../store/analyticsSlice';
import { NAnalytics } from '../service/transportTypes/NAnalytics';
import PATHS from '../constants/pathConstants';
import useDataPeriodInterval from '../helpers/hooks/useDataPeriodInterval';
import SettingsButton from '../components/base/SettingsButton';
import DataRangeSettingsOverlay from '../components/overlays/DataRangeSettingsOverlay';
import { isDateInInterval } from '../utilities/graphUtils';
import TimePerRoomChart from '../components/analytics/charts/TimePerRoomChart';
import { AnalyticsTableData } from '../components/analytics/AnalyticsHistoryComponent';
import IWatchPercentageEvent = NAnalytics.IWatchPercentageEvent;
import ILoginEvent = NAnalytics.ILoginEvent;

interface CollectContentDataReturn {
  videos: AnalyticsTableData<number>[];
  links: AnalyticsTableData<number>[];
  podcasts: AnalyticsTableData<number>[];
}
const collectContentData = (
  contentTriggers: NAnalytics.IContentTriggerEvent[],
  videoWatch: IWatchPercentageEvent[],
  podcastListen: IWatchPercentageEvent[],
  period: Interval,
): CollectContentDataReturn => {
  // Collect watch/download counts
  const unsorted = contentTriggers
    .filter((event) => isDateInInterval(event.created_at, period))
    .reduce<CollectContentDataReturn>(
      (acc, event) => {
        const link = acc.links.find((l) => l.title === event.data.url);
        const video = acc.videos.find((v) => v.title === event.data.url);
        const podcast = acc.podcasts.find((v) => v.title === event.data.url);
        switch (event.name) {
          case NAnalytics.EventNames.LinkTrigger:
            if (link) {
              link.data = (link.data ?? 0) + 1;
            } else {
              acc.links.push({
                id: event.data.url,
                title: event.data.url,
                data: 1,
              });
            }
            break;
          case NAnalytics.EventNames.VideoPlay:
            if (video) {
              video.data = (video.data ?? 0) + 1;
            } else {
              acc.videos.push({
                id: event.data.url,
                title: event.data.url,
                data: 1,
                percentage: 0,
              });
            }
            break;
          case NAnalytics.EventNames.PodcastPlay:
            if (podcast) {
              podcast.data = (podcast.data ?? 0) + 1;
            } else {
              acc.podcasts.push({
                id: event.data.url,
                title: event.data.url,
                data: 1,
              });
            }
            break;
          default:
            console.error('Unexpected event name on event:', event);
        }

        return acc;
      },
      {
        videos: [],
        links: [],
        podcasts: [],
      },
    );

  // Add in watch %'s
  videoWatch.forEach((event) => {
    const aggregate = unsorted.videos.find((v) => v.title === event.name);
    if (aggregate && (aggregate.percentage ?? 0) < event.data.PercentageWatched) {
      aggregate.percentage = Math.min(event.data.PercentageWatched, 100);
    }
  });
  podcastListen.forEach((event) => {
    const aggregate = unsorted.podcasts.find((v) => v.title === event.name);
    if (aggregate && (aggregate.percentage ?? 0) < event.data.PercentageWatched) {
      aggregate.percentage = Math.min(event.data.PercentageWatched, 100);
    }
  });

  return {
    videos: unsorted.videos.sort((a, b) => (b.data ?? 0) - (a.data ?? 0)),
    links: unsorted.links.sort((a, b) => (b.data ?? 0) - (a.data ?? 0)),
    podcasts: unsorted.podcasts.sort((a, b) => (b.data ?? 0) - (a.data ?? 0)),
  };
};

const collectLoginData = (
  logins: NAnalytics.ILoginEvent[],
  selectedUser: IVisitorDetails | undefined,
  period: Interval,
): AnalyticsTableData<number> & { events: ILoginEvent[] } => {
  if (selectedUser) {
    const loginsFiltered = logins.filter(
      (login) =>
        login.visitor_user_id === selectedUser.id && isDateInInterval(login.created_at, period),
    );
    return {
      id: 'LoginTotal',
      title: 'Login History',
      data: loginsFiltered.length,
      events: loginsFiltered,
    };
  }

  return {
    id: 'LoginTotal',
    title: 'Login History',
    data: 0,
    events: [],
  };
};

const UserData = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { tokenVerified, visitors } = useAppSelector((state) => state.user);
  const { timeSpent, contentTrigger, podcastListenPercent, videoWatchPercent, loginData } =
    useAppSelector((state) => state.analytics);

  const [settingsOpen, setSettingsOpen] = useState(false);

  const period = useDataPeriodInterval();
  const query = parse(location.search, { ignoreQueryPrefix: true });

  const userId = Number.parseInt(query.user as string);
  const selectedUser = visitors.find((u) => u.id === userId);

  const { videos, links, podcasts } = collectContentData(
    contentTrigger.filter((e) => e.visitor_user_id === selectedUser!.id),
    videoWatchPercent.filter((e) => e.visitor_user_id === selectedUser!.id),
    podcastListenPercent.filter((e) => e.visitor_user_id === selectedUser!.id),
    period,
  );

  const logins = collectLoginData(loginData, selectedUser, period);

  useEffect(() => {
    if (tokenVerified) {
      dispatch(loadVisitors());
      dispatch(getTimeSpentData());
      dispatch(getContentTriggerData());
      dispatch(getVideoWatchPercentData());
      dispatch(getPodcastListenPercentData());
    }
  }, [tokenVerified]);

  if (visitors.length && !selectedUser) {
    console.error(`Could not find user with id ${query.user}`);
    return <Redirect to={PATHS.USERS} />;
  }

  return (
    <AuthorizedPage>
      <PageHeader
        title={`User: ${selectedUser?.title} ${selectedUser?.first_name} ${selectedUser?.last_name}`}
        hintMessage="View user data"
        actions={[<SettingsButton onClick={() => setSettingsOpen(true)} />]}
      />
      <Grid className="dashboard-body">
        <Grid container item xs={12} spacing={2}>
          <Grid container item xs={12} sm={12} md={8}>
            <TimePerRoomChart
              events={timeSpent.filter((e) => e.visitor_user_id === selectedUser!.id)}
            />
          </Grid>
          {/* <Grid container item xs={6} sm={4} md={4}> */}
          {/*  <EventCountAnalyticsPanel */}
          {/*    name="Live Events Attended" */}
          {/*    category="User" */}
          {/*    data={[]} */}
          {/*    filter="" */}
          {/*  /> */}
          {/* </Grid> */}
          <Grid container item xs={6} sm={4} md={4}>
            <EventCountAnalyticsPanel
              name="Total Downloads - Resources"
              category="User"
              data={links}
              csvData={links.map(({ title, data }) => ({
                title,
                downloads: data,
              }))}
            />
          </Grid>
          <Grid container item xs={12} sm={6} md={4}>
            <PercentageListAnalyticsPanel
              name="Videos Played"
              category="User"
              data={videos}
              csvData={videos.map(({ title, data }) => ({
                title,
                downloads: data,
              }))}
            />
          </Grid>
          <Grid container item xs={12} sm={6} md={4}>
            <PercentageListAnalyticsPanel
              name="Podcasts Played"
              category="User"
              data={podcasts}
              csvData={podcasts.map(({ title, data }) => ({
                title,
                downloads: data,
              }))}
            />
          </Grid>
          <Grid container item xs={12} sm={6} md={4}>
            <EventCountAnalyticsPanel
              name="Logins"
              category="User"
              data={[logins]}
              csvData={logins.events.map((e) => ({
                eventId: e.id,
                loginTime: e.created_at,
              }))}
            />
          </Grid>
        </Grid>
      </Grid>
      <DataRangeSettingsOverlay
        open={settingsOpen}
        period={period}
        onClose={() => setSettingsOpen(false)}
      />
    </AuthorizedPage>
  );
};

export default UserData;
