import React, { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DESIGN from '../../constants/designConstants';
import { logout } from '../../store/userSlice';
import PATHS from '../../constants/pathConstants';

const useStyles = makeStyles((theme) => ({
  button: {
    background: DESIGN.COLOR_WHITE,
    height: '50px',
    borderRadius: '10px',
    textTransform: 'none',
  },
}));

interface HeaderProps {
  title: string;
  hintMessage: string;
  actions?: ReactNode[];
}

const PageHeader = (props: HeaderProps): JSX.Element => {
  const { title, hintMessage, actions } = props;

  return (
    <Grid
      container
      className={['header', 'page-header'].join(' ')}
      justifyContent="space-between"
      alignItems="center"
      wrap="nowrap"
    >
      <Grid container direction="column" alignItems="flex-start" item>
        <Grid item>
          <h3>{title}</h3>
        </Grid>
        <Grid item>
          <h4>{hintMessage}</h4>
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end" alignItems="center" item spacing={2}>
        {actions?.map((component, index) => (
          <Grid item key={index}>{component}</Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default PageHeader;
