import { KeysOf } from '../service/transportTypes/TransportTypes';

export const GRAPH_TYPES = {
  EVENT_COUNT: 'EVENT_COUNT',
  ANALYTICS_HISTORY_BROWSERS: 'ANALYTICS_HISTORY_BROWSERS',
  ANALYTICS_HISTORY_BROWSERS_EMPTY: 'ANALYTICS_HISTORY_BROWSERS_EMPTY',
} as const;

export type GraphType = KeysOf<typeof GRAPH_TYPES>;

export interface IOptionsFilter {
  id: string;
  name: string;
  value?: number;
}
export const defaultOptionsFilters: IOptionsFilter[] = [
  {
    id: '0',
    name: 'Last 7 Days',
    value: 7,
  },
  {
    id: '1',
    name: 'Last 14 Days',
    value: 14,
  },
  {
    id: '2',
    name: 'Last 30 Days',
    value: 30,
  },
];
export const viewMoreOptionFilters: IOptionsFilter[] = [
  {
    id: '0',
    name: 'View more',
  },
];
