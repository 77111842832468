import React from 'react';
import EventCountAnalyticsPanel from '../EventCountAnalyticsPanel';
import { useAppSelector } from '../../../store/store';
import useDataPeriodInterval from '../../../helpers/hooks/useDataPeriodInterval';
import { NAnalytics } from '../../../service/transportTypes/NAnalytics';
import { IVisitorDetails } from '../../../utilities/backendTypes';
import { isDateInInterval } from '../../../utilities/graphUtils';
import { ColumnData } from '../../base/TableDefault';
import { formatDateFromString, notEmpty } from '../../../utilities/functions';
import { AnalyticsTableData } from '../AnalyticsHistoryComponent';

interface MoreInfoRow {
  id: number;
  title: string;
  firstName: string;
  lastName: string;
  universalCode: string;
  specialty: string;
  country: string;
  date?: string;
  totalLogins?: number;
}

const columns: ColumnData<MoreInfoRow>[] = [
  {
    id: 'title',
    type: 'string',
    label: 'Title',
  },
  {
    id: 'firstName',
    type: 'string',
    label: 'First Name',
  },
  {
    id: 'lastName',
    type: 'string',
    label: 'Last Name',
  },
  {
    id: 'universalCode',
    type: 'string',
    label: 'Universal Code',
  },
  {
    id: 'specialty',
    type: 'string',
    label: 'Specialty',
  },
  {
    id: 'country',
    type: 'string',
    label: 'Country',
  },
];

const dateColumn: ColumnData<MoreInfoRow> = {
  id: 'date',
  type: 'date',
  label: 'Date/time',
  formatter: (dateStr: string) => formatDateFromString(dateStr),
};

const loginColumn: ColumnData<MoreInfoRow> = {
  id: 'totalLogins',
  type: 'number',
  label: 'Total Logins',
};

interface SummaryTableData extends AnalyticsTableData<number>{
  ids: number[]
}

const collectLoginData = (
  logins: NAnalytics.ILoginEvent[],
  registeredUsers: IVisitorDetails[],
  period: Interval,
): SummaryTableData[] => {
  console.log('collectLoginData', { logins, registeredUsers });

  const loginData = logins
    .filter((event) => isDateInInterval(event.created_at, period))
    .map<MoreInfoRow | null>((event) => {
      const user = registeredUsers.find((u) => u.id === event.visitor_user_id);
      if (user) {
        return {
          id: user.id,
          title: user.title,
          firstName: user.first_name,
          lastName: user.last_name,
          country: user.country,
          specialty: user.specialty,
          universalCode: user.menarini_uc,
          date: event.created_at,
        };
      }
      return null;
    })
    .filter(notEmpty);

  // Split registered users into those who have logged in and those who have not
  const [pendingLogin, loggedIn] = registeredUsers
    .map<MoreInfoRow>((user) => ({
      id: user.id,
      title: user.title,
      firstName: user.first_name,
      lastName: user.last_name,
      country: user.country,
      specialty: user.specialty,
      universalCode: user.menarini_uc,
    }))
    .reduce<[MoreInfoRow[], MoreInfoRow[]]>(
      ([pending, logged], user) => {
        const totalLogins = logins.filter((e) => e.visitor_user_id === user.id).length;

        if (totalLogins) {
          return [pending, [...logged, { ...user, totalLogins }]];
        }
        return [[...pending, user], logged];
      },
      [[], []],
    );

  // Separate those users who have logged in multiple times
  const repeatLogin = loggedIn.filter((user) => user.totalLogins && user.totalLogins > 1);

  return [
    {
      id: 'LoginTotal',
      title: 'Total user logins',
      data: loginData.length,
      ids: loginData.map((d) => d.id),
      moreInfo: {
        data: loginData,
        columns: [...columns, dateColumn],
      },
    },
    {
      id: 'RepeatLogin',
      title: 'Repeat logins',
      data: repeatLogin.length,
      ids: repeatLogin.map((user) => user.id),
      moreInfo: {
        data: repeatLogin,
        columns: [...columns, loginColumn],
      },
    },
    {
      id: 'LoginUnique',
      title: 'Unique user logins',
      data: loggedIn.length,
      ids: loggedIn.map((user) => user.id),
      moreInfo: {
        data: loggedIn,
        columns: [...columns, loginColumn],
      },
    },
    {
      id: 'LoginPending',
      title: 'Users pending first login',
      data: pendingLogin.length,
      ids: pendingLogin.map((u) => u.id),
      moreInfo: {
        data: pendingLogin,
        columns,
      },
    },
  ];
};

const collectFeedbackData = (
  feedbackMessages: NAnalytics.IFeedbackEvent[],
  period: Interval,
): SummaryTableData[] => {
  // Count unique users who have sent feedback messages
  const visitorIds = feedbackMessages
    .filter((event) => isDateInInterval(event.created_at, period))
    .reduce<number[]>((acc, event) => {
      if (!acc.includes(event.visitor_user_id)) {
        return [...acc, event.visitor_user_id];
      }
      return acc;
    }, []);

  const messages = feedbackMessages
    .filter((event) => isDateInInterval(event.created_at, period))
    .map((login) => login.visitor_user_id);

  return [
    {
      id: 'FeedbackUnique',
      title: 'Unique contact form users',
      data: visitorIds.length,
      ids: visitorIds,
      // showViewMore: true,
    },
    {
      id: 'FeedbackTotal',
      title: 'Total messages sent',
      data: messages.length,
      ids: messages,
      // showViewMore: true,
    },
  ];
};

export interface AnalyticsSummaryTableProps {}

const AnalyticsSummaryTable = (props: AnalyticsSummaryTableProps): JSX.Element => {
  const { visitors } = useAppSelector((state) => state.user);
  const { feedback, loginData, loadTime } = useAppSelector((state) => state.analytics);

  const period = useDataPeriodInterval();

  const otherData: SummaryTableData[] = [
    ...collectLoginData(loginData, visitors, period),
    ...collectFeedbackData(feedback, period),
    {
      id: 'loadTime',
      title: 'Avg. Load Time (s)',
      data: loadTime.reduce((acc, e) => acc + e.data.seconds, 0) / loadTime.length,
      ids: loadTime.map((e) => e.visitor_user_id),
    },
  ];
  return (
    <EventCountAnalyticsPanel
      name="Analytics Summary"
      category="Other"
      data={otherData}
      csvData={otherData.map((event) => ({
        title: event.title,
        logins: event.data,
        users: event.ids
          .map((id) => visitors.find((u) => u.id === id))
          .filter(notEmpty)
          .map((u) => `${u.title} ${u.first_name} ${u.last_name}`),
      }))}
      formatter={(v) => v.toLocaleString(undefined, { maximumFractionDigits: 2 })}
    />
  );
};

export default AnalyticsSummaryTable;
