import React from 'react';
import { Cancel, Check, CheckCircle, Close } from '@material-ui/icons';
import { Key } from '../utilities/frontendTypes';

export interface PasswordRequirementItem {
  label: string;
  satisfied: boolean;
  key: Key;
}

export interface PasswordRequirementsProps {
  requirements: PasswordRequirementItem[];
}

const PasswordRequirements = (props: PasswordRequirementsProps): JSX.Element => {
  const { requirements } = props;

  return (
    <div className="password-requirements">
      <p>Passwords must:</p>
      <ul className="password-requirements-list">
        {requirements.map((r) =>
          <li className="password-requirements__requirement" key={r.key}>
            {r.satisfied ?
              <CheckCircle className="password-requirements__icon-satisfied" />
              :
              <Cancel className="password-requirements__icon-unsatisfied" />}
            {r.label}
          </li>,
        )}
      </ul>
    </div>
  );
};

export default PasswordRequirements;
