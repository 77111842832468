import React, { ReactNode } from 'react';
import { Grid, Container, CircularProgress } from '@material-ui/core';
import SideNav from '../components/SideNav';
import { useAppSelector } from '../store/store';

export interface AuthorizedPageProps {
  children?: ReactNode;
}

const AuthorizedPage = (props: AuthorizedPageProps): JSX.Element => {
  const { children } = props;
  const { tokenVerified } = useAppSelector((state) => state.user);

  return (
    <Grid className="dashboard-container">
      <SideNav />
      <Grid className="dashboard-body-container">
        <Container>
          {tokenVerified ? (
            <div>{children}</div>
          ) : (
            <div className="dashboard-body-container__loading">
              <CircularProgress size={60} />
            </div>
          )}
        </Container>
      </Grid>
    </Grid>
  );
};

export default AuthorizedPage;
