const MESSAGES = {
  PASSWORD_SHORT_ERROR: 'Your password must be at least 8 characters long.',
  PASSWORD_INVALID_ERROR: 'Please enter a valid password.',
  PASSWORD_DIDNT_MATCH_ERROR: 'Passwords did not match.',
  RESET_PASSWORD_EMAIL_SENT: 'Reset password email sent.',
  LOGGED_IN_SUCCESSFULLY: 'Logged in successfully.',
  USER_SAVED: 'User Saved.',
  USER_ADDED: 'User Added.',
  USER_DELETED: 'User Deleted.',
  APPROVE_CHANGE: 'Are you sure you want to approve this change?',
  REJECT_CHANGE: 'Are you sure you want to reject this change?',
};
export default MESSAGES;
