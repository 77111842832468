import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';

const Embed = (): JSX.Element => {
  useEffect(() => {
    // ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);

    // add overflow hidden when rendering this component only
    const app = document.getElementById('root');
    if (app) {
      document.body.style.overflow = 'hidden';
      app.style.overflow = 'hidden';
    }
  }, []);

  const getQueryStringValue = (key: string) => decodeURIComponent(window.location.search.replace(new RegExp(`^(?:.*[&\\?]${encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&')}(?:\\=([^&]*))?)?.*$`, 'i'), '$1'));

  const url = getQueryStringValue('url');

  return (
    <div className="embed">
      <iframe
        sandbox="allow-same-origin allow-forms allow-scripts"
        allowFullScreen
        className="iframe"
        title="iframe"
        src={url}
        frameBorder="0"
        allow="camera; microphone; fullscreen; speaker; display-capture"
      />
    </div>
  );
};

export default Embed;
