import { Grid } from '@material-ui/core';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PATHS from '../constants/pathConstants';
import UnauthorizedPage from '../containers/UnauthorizedPage';
import { useAppDispatch, useAppSelector } from '../store/store';
import { setToken } from '../store/userSlice';

const Login = (): JSX.Element => {
  const { tokenVerified } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    if (tokenVerified) {
      // console.log('Login: Verified');
      history.push(PATHS.HOME);
    }
  }, [tokenVerified]);

  const handleSuccess = (cred: CredentialResponse) => {
    console.log('Success', cred);

    if (cred.credential) {
      dispatch(setToken(cred.credential));
    }
  };

  const handleFailure = () => {
    console.log('Failed to authenticate with google');
  };

  return (
    <UnauthorizedPage>
      <Grid className="login-container" container direction="column" item xs={8}>
        <h1 className="login-container__title">Dashboard</h1>
        <GoogleLogin
          onSuccess={handleSuccess}
          onError={handleFailure}
        />
      </Grid>
    </UnauthorizedPage>
  );
};

export default Login;
