import React, { FunctionComponent, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Unity from '../pages/Unity';
import Embed from '../pages/Embed';
import Calendly from '../pages/Calendly';
import Slido from '../pages/Slido';
import PATHS from '../constants/pathConstants';
import Login from '../pages/Login';
import Users from '../pages/Users';
import UserData from '../pages/UserData';
import Home from '../pages/Home';
import PrivateRoute from '../components/PrivateRoute';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import SetPassword from '../pages/SetPassword';
import SetPasswordSuccess from '../pages/SetPasswordSuccess';
import TermsOfUse from '../pages/TermsOfUse';
import RegistrationDisclaimer from '../pages/RegistrationDisclaimer';
import AnalyticsOverview from '../pages/AnalyticsOverview';
import Zoom from '../pages/Zoom';
import ZoomThanks from '../pages/ZoomThanks';
import ZoomAuditoriumView from '../pages/ZoomAuditoriumView';
import { loadToken, verifyLogin } from '../store/userSlice';
import { useAppDispatch, useAppSelector } from '../store/store';

export type AppProps = Record<string, never>;

const App: FunctionComponent<AppProps> = () => {
  const dispatch = useDispatch();
  const { token, tokenLoaded, tokenVerified } = useAppSelector((state) => state.user);

  useEffect(() => {
    // Verify tokens that have been saved to localstorage
    if (!tokenLoaded) {
      // console.log('App: Loading Token');
      dispatch(loadToken());
    }
  }, [tokenLoaded]);

  useEffect(() => {
    // Verify tokens that have been saved to localstorage
    if (token && tokenLoaded && !tokenVerified) {
      // console.log('App: Verifying Token');
      dispatch(verifyLogin());
    }
  }, [token, tokenVerified]);

  return (
    <Router>
      <Switch>
        <PrivateRoute exact path={PATHS.HOME} component={Home} />
        <PrivateRoute exact path={PATHS.ANALYTICS} component={AnalyticsOverview} />
        <PrivateRoute exact path={PATHS.USERS} component={Users} />
        <PrivateRoute exact path={PATHS.USER_DATA} component={UserData} />
        {/*  Public routes */}
        <Route exact path={PATHS.ADMIN} component={Login} />
        <Route exact path={PATHS.PRIVACY_POLICY} component={PrivacyPolicy} />
        <Route exact path={PATHS.REGISTRATION_DISCLAIMER} component={RegistrationDisclaimer} />
        <Route exact path={PATHS.TERMS_OF_USE} component={TermsOfUse} />
        <Route exact path={PATHS.EMBED} component={Embed} />
        {/* <Route exact path={PATHS.ZOOM} component={Zoom} /> */}
        <Route
          exact
          path={PATHS.ZOOM}
          render={() => <ZoomAuditoriumView />}
        />
        <Route exact path={PATHS.ZOOM_THANKS} component={ZoomThanks} />
        <Route exact path={PATHS.CALENDLY} component={Calendly} />
        <Route exact path={PATHS.SLIDO} component={Slido} />
        <Route exact path={PATHS.SET_PASSWORD} component={SetPassword} />
        <Route exact path={PATHS.SET_PASSWORD_SUCCESS} component={SetPasswordSuccess} />
        <Route exact path={PATHS.ROOT} component={Unity} />
        <Route component={Unity} />
      </Switch>
    </Router>
  );
};
export default App;
