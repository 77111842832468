import store from '../store/store';

const authHeader = (headers: Record<string, string>) => {
  // return authorization header with jwt token
  const { token } = store.getState().user;
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
};

export default authHeader;
