import React from 'react';
import { Grid } from '@material-ui/core';
import logo from '../assets/images/logo.png';

const RegistrationDisclaimer = (): JSX.Element => (
  <Grid className="privacy" container direction="column" item xs={12}>
    <Grid container justifyContent="space-between" alignItems="center" className="privacy__header">
      <h1 className="privacy__title">Registration Disclaimer</h1>
      <img src={logo} alt="Logo" />
    </Grid>
    <p>
      The Menarini Group Company you work with (“Company”, “we”) processes information
      about you (“Personal Data”) to (a) conduct polls, surveys, gather feedback and
      suggestions; (b) allow us to communicate with you, including sharing e-invites to
      register for our events and e-certificates of attendance, and post-meeting follow-ups; (c)
      provide you with scientific materials and activities and to tailor them on the basis of your
      needs, preferences and professional interests; and (d) to fulfil obligations stemming
      from laws and regulations (collectively, the &quot;Purposes&quot;). The Personal Data which we
      may process are the following: (1) professional/academic title; (2) name; (3) surname;
      (4) email correspondence address; (5) specialisations; (6) mobile phone number (7)
      images and videos of yourself; (8) data pertaining to the use and/or viewing, on your
      part, of our electronic/digital informational materials; and (9) other information pertaining
      to the profession and preferences with regards to the services we offer. By registering,
      you agree to our use of your Personal data in accordance with the Purposes. The
      Company shall use, process, retain and transmit your Personal Data in accordance with
      all applicable laws.
    </p>
  </Grid>
);

export default RegistrationDisclaimer;
