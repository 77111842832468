import React from 'react';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { makeStyles } from '@material-ui/core/styles';
import ToolTippedButton from './ToolTippedButton';

const useStyles = makeStyles((theme) => ({
  buttonIcon: {
    marginLeft: theme.spacing(1),
  },
}));

export interface SettingsButtonProps {
  onClick: () => void;
}

const SettingsButton = (props: SettingsButtonProps): JSX.Element => {
  const { onClick } = props;

  const classes = useStyles();

  return (
    <ToolTippedButton tooltip="Settings" onClick={onClick}>
      Settings
      <SettingsOutlinedIcon className={classes.buttonIcon} />
    </ToolTippedButton>
  );
};

export default SettingsButton;
