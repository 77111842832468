import React, { MouseEvent, ReactNode } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Grid, makeStyles } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const TransitionRight = React.forwardRef(
  (props: { children?: React.ReactElement<any, any> }, ref: React.Ref<unknown>) => (
    <Slide direction="right" ref={ref} {...props} />
  ),
);
const TransitionUp = React.forwardRef(
  (props: { children?: React.ReactElement<any, any> }, ref: React.Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...props} />
  ),
);
const TransitionLeft = React.forwardRef(
  (props: { children?: React.ReactElement<any, any> }, ref: React.Ref<unknown>) => (
    <Slide direction="left" ref={ref} {...props} />
  ),
);
export interface CustomModalProps {
  /*
   * Modal title
   * */
  title: string;
  /*
   * Modal transition RIGHT OR UP
   * */
  modalTransition?: string;
  /*
   * Show/Hide modal flag variable
   * */
  modalStatus: boolean;
  /*
   * Any react component to be displayed in the modal body
   * */
  children: ReactNode;
  /*
   * Function to be used on the Close button
   * */
  handleClose: (event: MouseEvent<HTMLButtonElement>) => void;
}

const useStyles = makeStyles({
  dialog: {
    background: 'white',
    borderRadius: 0,
    padding: '0px',
    minWidth: '800px',
    minHeight: '450px',
  },
});

export default function CustomModal(props: CustomModalProps) {
  const {
    title,
    children,
    modalStatus,
    modalTransition,
    handleClose,
  } = props;
  const classes = useStyles();
  const modalClasses = {
    paper: classes.dialog,
  };

  return (
    <Dialog
      open={modalStatus}
      keepMounted
      onClose={handleClose}
      aria-labelledby={title}
      aria-describedby={title}
      TransitionComponent={modalTransition === 'LEFT' ? TransitionLeft : TransitionUp}
      classes={modalClasses}
      maxWidth="lg"
    >
      <Toolbar className="modal__header">
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid container item xs={9} sm={10} md={10} lg={10}>
            <h3>{title}</h3>
          </Grid>
          <Grid container item xs={2} sm={2} md={1} lg={1} alignItems="center" justifyContent="center">
            <IconButton color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon style={{ fontSize: '30px' }} />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
      <DialogContent className="modal__content">
        {children}
      </DialogContent>
    </Dialog>
  );
}
