import React, { useEffect, useState } from 'react';
import { Grid, IconButton, LinearProgress, Tooltip, withStyles } from '@material-ui/core';
import DESIGN from '../../constants/designConstants';
import GraphHeader from './GraphHeader';
import { GRAPH_TYPES, GraphType } from '../../constants/analyticsConstants';
import { IVisitorDetails } from '../../utilities/backendTypes';
import CustomModal from '../CustomModal';
import TableDefault, { ColumnData, RowData } from '../base/TableDefault';
import { useAppDispatch } from '../../store/store';
import { loadVisitors } from '../../store/userSlice';
import { formatDateFromString } from '../../utilities/functions';
import { getLoginData } from '../../store/analyticsSlice';
import DownloadCSVButton from '../base/DownloadCSVButton';

const RedLinearProgress = withStyles({
  root: {
    background: DESIGN.COLOR_LIGHT_RED,
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 10,
  },
  barColorPrimary: {
    backgroundColor: DESIGN.COLOR_RED,
  },
})(LinearProgress);

const GreenLinearProgress = withStyles({
  root: {
    background: DESIGN.COLOR_LIGHT_GREEN,
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 10,
  },
  barColorPrimary: {
    backgroundColor: DESIGN.COLOR_GREEN,
  },
})(LinearProgress);

const columns: ColumnData<IVisitorDetails>[] = [
  {
    id: 'title',
    type: 'string',
    label: 'Title',
  },
  {
    id: 'first_name',
    type: 'string',
    label: 'First Name',
  },
  {
    id: 'last_name',
    type: 'string',
    label: 'Last Name',
  },
  {
    id: 'menarini_uc',
    type: 'string',
    label: 'Universal Code',
  },
  {
    id: 'specialty',
    type: 'string',
    label: 'Specialty',
  },
  {
    id: 'country',
    type: 'string',
    label: 'Country',
  },
];

const columnsExtraDetail: ColumnData<IVisitorDetails>[] = [
  {
    id: 'title',
    type: 'string',
    label: 'Title',
  },
  {
    id: 'first_name',
    type: 'string',
    label: 'First Name',
  },
  {
    id: 'last_name',
    type: 'string',
    label: 'Last Name',
  },
  {
    id: 'menarini_uc',
    type: 'string',
    label: 'Universal Code',
  },
  {
    id: 'specialty',
    type: 'string',
    label: 'Specialty',
  },
  {
    id: 'country',
    type: 'string',
    label: 'Country',
  },
  {
    id: 'created_at',
    type: 'date',
    label: 'Date/time',
    formatter: (dateStr: string) => formatDateFromString(dateStr),
  },
];

export interface MoreInfoData<T extends RowData> {
  data: T[];
  columns: ColumnData<T>[];
  csvData?: object[];
  csvFile?: string;
}

type AnalyticsValue = number | string;

export interface AnalyticsTableData<T extends AnalyticsValue> {
  id: number | string;
  title: string;
  data: T;
  percentage?: number;
  moreInfo?: MoreInfoData<any>;
}

export interface AnalyticsHistoryComponentProps<T extends AnalyticsValue> {
  name: string;
  category: string;
  type: GraphType;
  data?: AnalyticsTableData<T>[];
  csvFile?: string;
  csvData: object[];
  formatter?: (value: T) => string;
}

const AnalyticsHistoryComponent = <T extends AnalyticsValue>(
  props: AnalyticsHistoryComponentProps<T>,
): JSX.Element => {
  const { name, category, type, data, csvFile, csvData, formatter = (v) => v } = props;

  const dispatch = useAppDispatch();
  const [moreInfoData, setMoreInfoData] = useState<MoreInfoData<any> | undefined>();
  const [title, setTitle] = useState('');

  useEffect(() => {
    dispatch(loadVisitors());
    dispatch(getLoginData());
  }, []);

  const handleViewMoreClick = (currentData: AnalyticsTableData<T>) => {
    setTitle(currentData.title);
    setMoreInfoData(currentData.moreInfo);

    // if (currentData.users) {
    //   setMoreInfoData(currentData.users);
    // } else if (
    //   currentData.id === 'LoginUnique' ||
    //   currentData.id === 'RepeatLogin' ||
    //   currentData.id === 'FeedbackUnique' ||
    //   currentData.id === 'FeedbackTotal'
    // ) {
    //   // for this info we just need the visitor details
    //   setColumnsData(columns);
    //   const usersData: IVisitorDetails[] = [];
    //   if (currentData && currentData.ids) {
    //     currentData.ids.forEach((id) => {
    //       const visitor = visitors.find((vis) => id === vis.id);
    //       if (visitor) {
    //         usersData.push(visitor);
    //       }
    //     });
    //   }
    //   setMoreInfoData(usersData);
    // } else if (currentData.ids) {
    //   const usersData: IVisitorDetails[] = [];
    //   // for this info we need the visitor details and the login data
    //   currentData.ids.forEach((id) => {
    //     const foundLogin = loginData.filter((vis) => id === vis.id);
    //     if (foundLogin) {
    //       foundLogin.forEach((login) => {
    //         const visitor = visitors.find((vis) => login.visitor_user_id === vis.id);
    //         if (visitor) {
    //           if (foundLogin[0].created_at) {
    //             setColumnsData(columnsExtraDetail);
    //           }
    //           const newVisitor = { ...visitor, created_at: foundLogin[0].created_at };
    //           usersData.push(newVisitor);
    //         }
    //       });
    //     }
    //   });
    //   setMoreInfoData(usersData);
    // }
  };

  return (
    <Grid className="logins" container>
      <GraphHeader
        name={name}
        category={category}
        actionButtons={[<DownloadCSVButton downloadData={csvData} fileName={csvFile ?? name} />]}
      />

      <Grid container className="logins-list">
        {data &&
          data.map((login, index) => (
            <Grid
              key={`${login.id}-login-${login.title}`}
              container
              alignItems="center"
              className={`logins-list__row ${
                index === data.length - 1 && 'logins-list__row--last'
              }`}
            >
              <Grid
                item
                xs={
                  type === GRAPH_TYPES.ANALYTICS_HISTORY_BROWSERS ||
                  type === GRAPH_TYPES.ANALYTICS_HISTORY_BROWSERS_EMPTY
                    ? 5
                    : 8
                }
                md={
                  type === GRAPH_TYPES.ANALYTICS_HISTORY_BROWSERS ||
                  type === GRAPH_TYPES.ANALYTICS_HISTORY_BROWSERS_EMPTY
                    ? 5
                    : 8
                }
                className="logins-list__location"
              >
                {login.moreInfo ? (
                  <span onClick={() => handleViewMoreClick(login)} role="button" aria-hidden="true">
                    <Tooltip title="View more">
                      <IconButton aria-label="View more">
                        <p>{login.title}</p>
                      </IconButton>
                    </Tooltip>
                  </span>
                ) : (
                  <p>{login.title}</p>
                )}
              </Grid>
              {type === GRAPH_TYPES.ANALYTICS_HISTORY_BROWSERS && (
                <Grid item xs={4}>
                  <Tooltip title={`${login.percentage?.toFixed(2)}%`}>
                    {index === 0 ? (
                      <RedLinearProgress variant="determinate" value={login.percentage} />
                    ) : (
                      <GreenLinearProgress variant="determinate" value={login.percentage} />
                    )}
                  </Tooltip>
                </Grid>
              )}
              {type === GRAPH_TYPES.ANALYTICS_HISTORY_BROWSERS_EMPTY && (
                <Grid item xs={4}>
                  <Tooltip title={`${login.percentage?.toFixed(2)}%`}>
                    <GreenLinearProgress variant="determinate" value={login.percentage} />
                  </Tooltip>
                </Grid>
              )}
              {login.moreInfo ? (
                <Grid
                  container
                  item
                  xs={3}
                  justifyContent="flex-end"
                  className="logins-list__count"
                  onClick={() => handleViewMoreClick(login)}
                  role="button"
                >
                  <Tooltip title="View more">
                    <IconButton aria-label="View more">
                      <p>{formatter(login.data)}</p>
                    </IconButton>
                  </Tooltip>
                </Grid>
              ) : (
                <Grid
                  container
                  item
                  xs={3}
                  justifyContent="flex-end"
                  className="logins-list__count"
                >
                  <p>{formatter(login.data)}</p>
                </Grid>
              )}
            </Grid>
          ))}
      </Grid>
      {moreInfoData && (
        <CustomModal title={title} modalStatus handleClose={() => setMoreInfoData(undefined)}>
          <TableDefault<RowData>
            columns={moreInfoData.columns}
            rows={moreInfoData.data}
            csvData={moreInfoData.csvData ?? moreInfoData.data}
            csvFile={moreInfoData.csvFile ?? title}
            filters={[]}
            hideAddButton
            hideFilterButton
            hideDeleteButton
            hideHeader
            viewDataOnly
          />
        </CustomModal>
      )}
    </Grid>
  );
};

export default AnalyticsHistoryComponent;
