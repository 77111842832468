import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAppSelector } from '../store/store';
import PATHS from '../constants/pathConstants';

interface PrivateRouteProps extends RouteProps {
  // tslint:disable-next-line:no-any
  component: any;
}

const PrivateRoute = (props: PrivateRouteProps): JSX.Element => {
  const { component: Component, ...rest } = props;
  const { token, tokenLoaded } = useAppSelector((state) => state.user);

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        // No token, return to login
        if (!token && tokenLoaded) {
          console.log('PrivateRoute: Redirect');
          return <Redirect to={{ pathname: PATHS.ADMIN, state: { from: routeProps.location } }} />;
        }

        // success
        return <Component {...routeProps} />;
      }}
    />
  );
};

export default PrivateRoute;
