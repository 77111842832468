import React from 'react';
import { Button, ButtonProps, Fade, LinearProgress } from '@material-ui/core';

export interface ButtonDefaultProps extends ButtonProps {
  large?: boolean;
  alt?: boolean;
  isLoading?: boolean;
}

const ButtonDefault = (props: ButtonDefaultProps): JSX.Element => {
  const { large, alt, className, isLoading, children, ...others } = props;

  return (
    <Button
      {...others}
      className={`btn ${alt ? 'btn-alt' : ''} ${large ? 'btn-lrg' : ''} ${className}`}
    >
      {!isLoading && children}
      {isLoading && (
        <Fade
          in={isLoading}
          style={{
            transitionDelay: isLoading ? '800ms' : '0ms',
          }}
          unmountOnExit
        >
          <LinearProgress />
        </Fade>
      )}
    </Button>
  );
};

export default ButtonDefault;
