import React from 'react';
import { Button, Grid, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { IAnalyticsBasicData } from '../../utilities/backendTypes';
import DownloadCSVButton from '../base/DownloadCSVButton';

export interface SummaryButtonData {
  title: string;
  value: string | number;
}

export interface SummaryButtonsComponentProps {
  data: SummaryButtonData[];
}

const SummaryButtons = (props: SummaryButtonsComponentProps): JSX.Element => {
  const { data } = props;
  return (
    <Grid className="summary-buttons" container>
      <div className="summary-buttons__wrapper">
        {data.map((item) => (
          <div className="summary-buttons__child" key={item.title}>
            <p>{item.title}</p>
            <h1>{item.value ? item.value : 'N/A'}</h1>
            <Grid item className="summary-buttons__actions">
              <DownloadCSVButton downloadData={[item]} fileName={item.title ?? 'export'} />
            </Grid>
          </div>
        ))}
      </div>
    </Grid>
  );
};

export default SummaryButtons;
