const ROOT = '/';

const ADMIN = '/admin';
const HOME = `${ADMIN}/home`;
const USERS = `${ADMIN}/users`;
const USER_DATA = `${ADMIN}/user-data`;
const ANALYTICS = `${ADMIN}/analytics`;

const PRIVACY_POLICY = '/privacy-policy';
const TERMS_OF_USE = '/terms-of-use';
const REGISTRATION_DISCLAIMER = '/registration-disclaimer';
const EMBED = '/embed';
const CALENDLY = '/calendly';
const SLIDO = '/slido';
const SET_PASSWORD = '/set-password';
const SET_PASSWORD_SUCCESS = '/set-password/success';
const FORGOT_PASSWORD = '/forgot-password';
const ANALYTICS_DETAILS = `${ANALYTICS}/:details`;
const ANALYTICS_UNIQUE_LOGINS = 'unique-logins';
const ANALYTICS_BROWSER_TYPES = 'browser-types';
const ANALYTICS_PDFS_DOWNLOADED = 'pdfs-downloaded';
const UNITY = '/virtual-platform';
const ZOOM = '/zoom';
const ZOOM_AUDITORIUM = '/zoom/auditorium';
const ZOOM_THANKS = '/zoom-thanks';

export default {
  ROOT,
  PRIVACY_POLICY,
  REGISTRATION_DISCLAIMER,
  TERMS_OF_USE,
  HOME,
  ADMIN,
  USERS,
  USER_DATA,
  EMBED,
  SET_PASSWORD,
  SET_PASSWORD_SUCCESS,
  FORGOT_PASSWORD,
  ANALYTICS,
  ANALYTICS_DETAILS,
  ANALYTICS_UNIQUE_LOGINS,
  ANALYTICS_BROWSER_TYPES,
  ANALYTICS_PDFS_DOWNLOADED,
  SLIDO,
  CALENDLY,
  UNITY,
  ZOOM,
  ZOOM_AUDITORIUM,
  ZOOM_THANKS,
};
