import React from 'react';
import { Grid } from '@material-ui/core';
import logo from '../assets/images/logo.png';

const PrivacyPolicy = (): JSX.Element => (
  <Grid className="privacy" container direction="column" item xs={12}>
    <Grid container justifyContent="space-between" alignItems="center" className="privacy__header">
      <h1 className="privacy__title">Privacy Policy</h1>
      <img src={logo} alt="Logo" />
    </Grid>
    <p>
      For the purposes of this Privacy Policy,
      the words herein will have the same meaning as in the Terms of Use,
      except where otherwise expressly defined. In addition,
      the following words shall have the following meaning:
    </p>
    <ul>
      <li>
        "Personal Data" means data, whether true or not,
        about an individual who can be identified from that data or from that data and other
        information to which we have or are likely to have access;
      </li>
      <li>
        "Process", in relation to Personal Data, means: (i) to carry out any operation or
        set of operations in relation to the Personal Data, and includes recording,
        holding, organisation, adaptation/alteration, retrieval, combination,
        transmission or erasure/ destruction; and (ii) copy, use, access, display, run,
        store, review, manage, modify, transform, translate, extract components into another work,
        integrate or incorporate as part of a derivative work, and (iii) to permit others to do
        (i) and (ii). "Processing" shall have the corresponding meaning as a verb for the same.
      </li>
      <li>
        "Disclose" means to disclose, forward, communicate, transmit, transfer, export,
        supply, provide, show, display, exhibit, grant access to, and enable or facilitate the
        receipt of personal data. "Disclosure"
        shall have the corresponding meaning as a noun for the same.
      </li>
      <li>
        "Menarini" means A. Menarini Asia-Pacific Holdings Pte. Ltd.,
        including its subsidiaries and affiliates.
      </li>
    </ul>
    <p>Consent. By using or accessing this website
      (
      <a href="https://menarinicardiolounge.com/)" target="_blank" rel="noreferrer">
        https://menarinicardiolounge.com/
      </a>)
      ("Website"), you agree to be bound by the terms of the Privacy Policy
      as well as by the Terms of Use Agreement of Menarini (where applicable).
      Whenever you submit Personal Data via this Website for any reason whatsoever
      (including without limitation the submission of (i) testimonials in relation to Menarini's
      .goods and/or services, (ii) any information in relation to a job application at Menarini,
      or (iii) a query in relation to any of Menarini's products) you consent to the collection,
      use, Processing and Disclosure of that Personal Data by
      Menarini in accordance with this Privacy Policy.
    </p>
    <p>Collection of Personal Data. Menarini understands the importance of
      protecting your Personal Data. Menarini may collect Personal Data from you
      by asking specific questions or allowing you
      to communicate directly with us via e-mail,
      surveys, questionnaires, feedback forms or the provision of testimonials.
    </p>
    <p>
      Menarini may also collect information about your visits to our Website,
      including your Personal Data, without you submitting any such Personal
      Data directly to us. As you navigate through a Website,
      information may be collected using technological means such as cookies,
      tags, web beacons and other navigational
      data collection tools, as explained in this document.
    </p>
    <p>Navigation Data. The Website uses cookies,
      tags and web beacons. Cookies are small
      text files that this Website sends to your computer's
      hard drive to identify the computer you are using.
      Tags are smaller than cookies and tell the Website server
      information such as the Internet Protocol address and the browser
      types related to your computer.
      Web beacons are usually invisible to the user but are embedded
      in a web page or email that tracks whether a user has viewed a web page or email.
      Other navigational data collection tools are used for system management, to improve
      the Content of the Website, and for market research purposes.
    </p>
    <p>By browsing the Website, you consent to the use of Cookies,
      Tags and Web beacons for the purposes specified in this Privacy Policy.
    </p>
    <h1>What Cookies We Use</h1>
    <p>There exist two macro-categories of cookies, namely
      "technical cookies" and "profiling cookies".
    </p>
    <p>Technical cookies are necessary for the correct functioning of a
      .website and make navigation possible for the user; without them,
      the user may be unable to view the website's pages correctly
      or to use some services.
    </p>
    <p>Profiling cookies are designed to create profiles of the user so as to
      send on-line commercial ads in line with
      the preferences the user has expressed during navigation.
    </p>
    <p>Cookies can further be classified as:</p>
    <ul>
      <li>"session" cookies, which are immediately deleted after
        the navigation browser is closed;
      </li>
      <li>"persistent" cookies, which are stored in the browser for a given period of time.
        Persistent cookies are typically used to recognise the device that connects to a
        particular website so as to render the authentication operations easier for the user;
      </li>
      <li>"site's own" cookies, also known as "first party" cookies,
        generated and managed directly by the
        manager of the website that the user is navigating; and
      </li>
      <li>"third party" cookies, generated and managed by subjects
        other than the manager of the website that the user is navigating.
      </li>
    </ul>
    <p>Our Website uses the following types of cookies:</p>
    <ul>
      <li>"first party" cookies, which can be either "session" or "persistent".
        These are necessary to make the navigation of the Website possible,
        and are required for purposes of internal security and system administration;
      </li>
      <li>"third-party" cookies, which can be either "session" or "persistent".
        Again, these are necessary to enable the user to use those multimedia
        elements (such as images and videos) which are present on the Website;
      </li>
      <li>"third-party" or "persistent" cookies used by the Website in order to
        send statistical information to the Google Analytics system, through which
        Menarini may carry out statistical analyses of the accesses/visits to
        the Website. These cookies are used exclusively for statistical purposes
        and retain information in aggregate form. Using a pair of cookies (respectively,
        a persistent cookie and a session cookie, the latter of which is deleted when
        the user closes the browser), Google Analytics also saves the timestamp of the
        start of the visit and the exit from the Website. It is possible to prevent Google
        from using cookies to collect data, and therefore to prevent their consequent processing,
        by downloading and installing the browser plug-in available at the following website:
        http://tools.google.com/dlpage/gaoptout?hl=it
      </li>
      <li>"third-party" or "persistent" cookies used by the Website to include in
        its pages the buttons of some social networks (such as LinkedIn).
        By selecting one of these buttons, a user may publish on his/her
        personal page of the relevant social network the content of the
        Website's webpage they are navigating; and
      </li>
      <li>profiling cookies which are used by third parties to send the
        user commercial ads in line with the preferences the user
        has expressed when navigating the internet.
      </li>
    </ul>
    <p>You may disable the majority of third-party cookies in use on this website
      by following the procedures described in this Privacy Policy
      (available by clicking on the links provided in the table below)
      - or by amending their browser's settings.
    </p>
    <p>The following table contains the details of the cookies that are present on the Website.
      For each "third-party" cookie, you will find a link to the privacy policy of the relevant
      subject who generates and manages such cookies.
    </p>
    <table>
      <tbody>
        <tr>
          <th>COOKIES</th>
          <th>TYPE</th>
          <th>PURPOSE</th>
          <th>PRIVACY POLICY</th>
        </tr>
        <tr>
          <td>
            <a href="https://menarinicardiolounge.com/)" target="_blank" rel="noreferrer">
              https://menarinicardiolounge.com/
            </a>
          </td>
          <td>First Party - Session and persistent</td>
          <td>Cookies required to make navigation on the Website possible,
            for internal security and administration purposes.
          </td>
          <td>This document</td>
        </tr>
        <tr>
          <td>
            <a href="https://google.com/)" target="_blank" rel="noreferrer">
              https://google.com
            </a>
          </td>
          <td>Third party - persistent</td>
          <td>Cookie for the Google Analytics service</td>
          <td>https://www.google.com/analytics/learn/privacy.html?hl=it
            http://www.google.com/policies/privacy/
            https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage#gajs
            https://tools.google.com/dlpage/gaoptout
            https://support.google.com/analytics/answer/6004245
          </td>
        </tr>
      </tbody>
    </table>
    <h1>How to disable cookies in the browser's settings</h1>
    <p>A guide to how to manage cookies in the most common browsers is provided below</p>
    <ul>
      <li>Chrome</li>
      <li>Mozilla Firefox</li>
      <li>Microsoft Edge</li>
      <li>Safari</li>
      <li>Opera</li>
    </ul>
    <p><span className="bold">Use and Process of Personal Data. </span>
      Menarini may use and Process your Personal Data for the following purposes:
    </p>
    <ul>
      <li>conduct polls, surveys, gather feedback and suggestions; </li>
      <li>allow us to communicate with you, including sharing e-invites
        to register for our events and e-certificates of attendance,
        and post-meeting follow-ups;
      </li>
      <li>provide you with scientific materials and activities and to
        tailor them on the basis of your needs, preferences and
        professional interests; and
      </li>
      <li>to fulfil obligations stemming from laws and regulations.</li>
    </ul>

    <p><span className="bold">Disclosure and Transfer of Personal Data. </span>
      Menarini may disclose and transfer your Personal Data,
      including transfer to foreign jurisdictions:
    </p>
    <ul>
      <li>
        to third parties:
        <ul>
          <li>for the rendering of services or conduct of
            legitimate business activity related to this Website;
          </li>
          <li>to respond to your requests for customer service;</li>
          <li>to protect and enforce our rights, property or personal safety to the extent
            permitted or not prohibited by applicable law.
          </li>
        </ul>
      </li>
      <li>
        to any competent legal and/or regulatory authority and law enforcement agencies:
        <ul>
          <li>in compliance with legal process, order of court and applicable law; and/or</li>
          <li>to enforce our rights arising out of or in connection with the Terms of Use,
            Privacy Policy and any other contracts with you.
          </li>
        </ul>
      </li>
      <li>
        to our successors-in-title, prospective sellers or buyers of any part or the
        whole of our business, in connection with a merger,
        acquisition or sale of any part or the whole of our business.
      </li>
    </ul>

    <p><span className="bold">Note: </span>
      Transfer of data may include transfers to territories where our
      networks or third party hosting providers' networks are located.
    </p>
    <p><span className="bold">Care of Personal Data. </span>
      Menarini will make a reasonable effort to ensure
      that Personal Data collected by us or on our behalf
      is accurate and complete. Menarini will make
      a reasonable effort to protect Personal Data in
      our possession or control by making reasonable
      security arrangements to prevent unauthorised access,
      collection, use, disclosure, copying, modification, disposal
      or similar risks. Notwithstanding the foregoing, please be aware
      that the Internet is not a completely secure or error-free environment.
      Menarini cannot fully guarantee:
    </p>
    <ul>
      <li>the confidentiality of any Personal Data you submit to us over the Internet;</li>
      <li>that unauthorized persons will not view your Personal Data or emails; or</li>
      <li>that your Personal Data will not be altered, corrupted or destroyed by
        third party circumvention of our reasonable security measures that have
        been in place to safeguard your Personal Data.
      </li>
    </ul>
    <p>We urge you to minimize security risks by regularly updating your antivirus software.</p>
    <p><span className="bold">Access to Personal Data. </span>You may request in writing for us to provide you with:</p>
    <ul>
      <li>your Personal Data that is in Menarini's possession or control;
        and your Personal Data that is in Menarini's possession or control; and
      </li>
      <li>information about the ways in which your Personal Data has been or may have been
        used by Menarini within a year before the date of your written request;
      </li>
    </ul>
    <p>and Menarini will exercise reasonable endeavours to
      fulfil such requests subject to applicable law.
      Any request for access to Personal Data pursuant
      to this clause shall be made in writing at the contact details set out below.
    </p>
    <p><span className="bold">Update and Correction of Personal Data. </span>
      You are entitled to request in writing for Menarini to
      update your Personal Data or to correct an error
      or omission in your Personal Data that is in our
      possession or control. Unless Menarini is satisfied on reasonable
      grounds that a correction should not be made, we will
      exercise reasonable endeavours to make such correction as soon as practicable,
      and send the corrected Personal Data to organisations to which the Personal
      Data was disclosed by us within a year before the date the correction was made
      (where applicable). Any request for update or correction of Personal
      Data pursuant to this clause shall be made in writing to us at the contact
      details set out below.
    </p>
    <p><span className="bold">Retention of Personal Data. </span>
      Menarini may need to retain such records containing your
      Personal Data as may be necessary or desirable to comply with applicable
      law or regulation, and to retain such information as long as the purposes
      for which the Personal Data was collected are still being served and retention
      is necessary for Menarini's legal or business purposes, including
      to address disputes or establish our rights (whether such disputes
      or matters have arisen, or could arise in the future).
    </p>
    <p><span className="bold">Withdrawal of Consent. </span>Your use of any part of this Website
      indicates your acceptance of this Privacy Policy and your consent
      to the collection, use, Processing and Disclosure of your Personal
      Data in accordance with this Privacy Policy. If you would like
      to withdraw your consent given for collection, use, Processing or
      Disclosure of your Personal Data for the purposes set out in this Privacy Policy,
      please immediately refrain from and cease all usage of this Website and provide
      us with reasonable notice of your withdrawal of consent in writing to us at the
      contact details set out below. On receipt of your written notice, we will cease
      the collection, use, Processing and Disclosure of your Personal Data within a
      reasonable time unless allowed by any applicable law or regulation but please note
      that the likely consequence of withdrawal of consent will be that you will not be
      able to access or use this website.
    </p>
    <p><span className="bold">Third Party Websites. </span>
      This Website may contain links or references to other
      websites which are maintained by third parties over
      whom we have no control over. This Privacy Policy does not
      apply to third party websites. You should refer to the privacy
      policies of the relevant third party websites to learn more
      about how their personal data are processed
      (including by the use of Cookies, web beacons and tags)
    </p>
    <p>Contact. If you have any queries about the collection, use,
      Processing and Disclosure of your Personal Data pursuant to
      this Privacy Policy, you may contact our Data Protection
      Officer at the following details below:
    </p>
    <p>E-mail : privacy@menariniapac.com</p>
    <p>Attention : Data Protection Officer</p>
    <p>Address : 30 Pasir Panjang Road, #08-32 Mapletree Business City, Singapore 117440</p>
  </Grid>
);

export default PrivacyPolicy;
