import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { formatISO } from 'date-fns';
import useMessageHandler, { MessageData } from '../helpers/hooks/useMessageHandler';
import { ZoomMeetingData } from '../helpers/hooks/useZoomMeeting';

declare global {
  interface Window {
    unityInstance?: {
      SendMessage: (objectName: string, methodName: string, value?: any) => void;
    };
  }
}

// const OpenZoomMessageType = 'OpenZoom' as const;
// interface OpenZoomMessage extends MessageData, ZoomMeetingData {
//   type: typeof OpenZoomMessageType;
// }

const UserMessageTypes = {
  Register: 'UserRegister',
  Login: 'UserLogin',
  ForgotPassword: 'ForgetPassword',
} as const;

interface UserLoginMessage {
  type: typeof UserMessageTypes.Login;
  user_id: number;
  token: string;
}

interface UserRegisterMessage {
  type: typeof UserMessageTypes.Register;
}

interface ForgotPasswordMessage {
  type: typeof UserMessageTypes.ForgotPassword;
}

const Unity = (): JSX.Element => {
  // const [ZoomComponent, StartMeeting] = useZoomMeeting();

  useEffect(() => {
    localStorage.setItem('loadStartTime', formatISO(new Date()));
    const buildUrl = 'Build';
    const loaderUrl = `${buildUrl}/LocalServer.loader.js`;

    const config = {
      dataUrl: `${buildUrl}/LocalServer.data`,
      frameworkUrl: `/${buildUrl}/LocalServer.framework.js`,
      codeUrl: `${buildUrl}/LocalServer.wasm`,
      streamingAssetsUrl: 'StreamingAssets',
      companyName: 'SpacesInteractive',
      productName: 'Menarini',
      productVersion: '0.1',
    };

    const container = document.getElementById('unity-container');
    const canvas = document.getElementById('unity-canvas');
    const loadingBar = document.getElementById('unity-loading-bar');
    const progressBarFull = document.getElementById('unity-progress-bar-full');

    function isCanvas(obj: HTMLCanvasElement | HTMLElement): obj is HTMLCanvasElement {
      return obj.tagName === 'CANVAS';
    }

    // calculating ratio dynamically.
    if (canvas) {
      if (isCanvas(canvas)) {
        canvas.width = canvas.clientWidth * window.devicePixelRatio;
        canvas.height = canvas.clientHeight * window.devicePixelRatio;
        if (canvas && canvas.style && canvas.style.width) {
          canvas.style.width = (canvas.clientWidth * window.devicePixelRatio).toString();
        }
        if (canvas && canvas.style && canvas.style.height) {
          canvas.style.height = (canvas.clientHeight * window.devicePixelRatio).toString();
        }
      }
    }

    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
      if (container) container.className = 'unity-mobile';
    } else {
      if (canvas && canvas.style && canvas.style.width) canvas.style.width = '100%';
      if (canvas && canvas.style && canvas.style.height) canvas.style.height = '100%';
    }

    if (loadingBar) loadingBar.style.display = 'block';

    const script = document.createElement('script');
    // script.src = loaderUrl;
    script.src = `/${loaderUrl}`;

    script.onload = () => {
      createUnityInstance(canvas, config, (data) => {
        if (progressBarFull) progressBarFull.style.width = `${100 * data}%`;
      })
        .then((unityInstance) => {
          localStorage.setItem('loadEndTime', formatISO(new Date()));
          window.unityInstance = unityInstance;
          if (loadingBar) loadingBar.style.display = 'none';

          // class to fix the height within the experience
          if (canvas) canvas.classList.add('mobile-fix');
        })
        .catch((error) => {
          console.log(error);
        });
    };
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
  }, []);

  // Make background transparent
  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      body.classList.add('zoom-overlay');
    }

    return () => {
      if (body) {
        body.classList.remove('zoom-overlay');
      }
    };
  });

  // useMessageHandler<OpenZoomMessage>(OpenZoomMessageType, (data) => {
  //   StartMeeting(data);
  // });

  useMessageHandler<UserLoginMessage>(UserMessageTypes.Login, (loginEvent) => {
    console.log('UserLoginMessage', loginEvent);
    ReactGA.event('login', { method: 'direct' });
  });

  useMessageHandler<UserRegisterMessage>(UserMessageTypes.Register, (registerEvent) => {
    console.log('UserRegisterMessage', registerEvent);
    ReactGA.event('sign_up', { method: 'direct' });
  });

  useMessageHandler<ForgotPasswordMessage>(UserMessageTypes.ForgotPassword, (pwEvent) => {
    console.log('ForgotPasswordMessage', pwEvent);
    ReactGA.event('forgot_password');
  });

  return (
    <div className="unity">
      <div id="unity-container" className="unity-desktop">
        <canvas id="unity-canvas" tabIndex={-1} />
        <div id="unity-loading-bar">
          <div className="message">
            <h1>MENARINI</h1>
            <h1>CARDIO LOUNGE</h1>
          </div>
          <div id="unity-logo" />
          <div id="unity-progress-bar-empty">
            <div id="unity-progress-bar-full" />
          </div>
        </div>
      </div>

      {/*  <ZoomComponent /> */}
    </div>
  );
};

export default Unity;
