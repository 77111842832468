// const PROJECT_ID = 19; // STAGING
const PROJECT_ID = 4; // PRODUCTION
const SERVICE_ID = 6;
const EMAIL_INTEGRATION_ID = 1;

const API = {
  PROJECT_ID,
  SERVICE_ID,
  EMAIL_INTEGRATION_ID,
  PROJECT: '/project',
  ANALYTICS: '/analytics',
  HISTORY: '/history',
  LOGIN_DATA: '/logindata',
  SERVICE: '/service',
  USER: '/user',
  VISITOR: '/visitor',
  FORGOT_PASSWORD: '/visitor/reset',
  RESET_PASSWORD: '/visitor/reset',
  DEFAULT: '',
  ADMIN: '/auth/admin',
  ZOOM: '/zoom',
  DATA_CAPTURE: `/project/${PROJECT_ID}/data-capture`,
  FORM: `/project/${PROJECT_ID}/${SERVICE_ID}/form`,
};

export default API;
