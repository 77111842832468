import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';

const Calendly = (): JSX.Element => {
  const getQueryStringValue = (key: string) => decodeURIComponent(window.location.search.replace(new RegExp(`^(?:.*[&\\?]${encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&')}(?:\\=([^&]*))?)?.*$`, 'i'), '$1'));
  const url = getQueryStringValue('url');

  useEffect(() => {
    // ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <div className="calendly">
      <div
        className="calendly-inline-widget calendly"
        data-url={`${url}?primary_color=ef4135`}
      />
    </div>
  );
};

export default Calendly;
