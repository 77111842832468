import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import AuthorizedPage from '../containers/AuthorizedPage';
import PageHeader from '../components/base/PageHeader';
import { useAppDispatch, useAppSelector } from '../store/store';
import { IVisitorDetails } from '../utilities/backendTypes';
import SummaryButtons from '../components/analytics/SummaryButtons';
import { loadVisitors } from '../store/userSlice';
import EventCountAnalyticsPanel from '../components/analytics/EventCountAnalyticsPanel';
import {
  getContentTriggerData,
  getDeletedData,
  getFeedbackData, getLoadTimeData,
  getLoginData, getPodcastListenPercentData,
  getTimeSpentData, getVideoWatchPercentData,
} from '../store/analyticsSlice';
import DataRangeSettingsOverlay from '../components/overlays/DataRangeSettingsOverlay';
import { isDateInInterval } from '../utilities/graphUtils';
import useDataPeriodInterval from '../helpers/hooks/useDataPeriodInterval';
import SettingsButton from '../components/base/SettingsButton';
import TimePerRoomChart from '../components/analytics/charts/TimePerRoomChart';
import AnalyticsSummaryTable from '../components/analytics/charts/AnalyticsSummaryTable';
import { AnalyticsTableData } from '../components/analytics/AnalyticsHistoryComponent';

const collectCountryData = (
  registeredUsers: IVisitorDetails[],
  period: Interval,
): AnalyticsTableData<number>[] => {
  const countryTotal = registeredUsers
    .filter((v) => isDateInInterval(v.created_at, period))
    .reduce<Record<string, number>>((acc, user) => {
      acc[user.country] = (acc[user.country] ?? 0) + 1;
      return acc;
    }, {});

  return Object.keys(countryTotal)
    .sort((a, b) => countryTotal[b] - countryTotal[a])
    .map((key) => ({
      id: key,
      title: key,
      data: countryTotal[key],
    }));
};

const Home = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const { timeSpent, deletedData } = useAppSelector((state) => state.analytics);
  const { tokenVerified, visitors } = useAppSelector((state) => state.user);
  const period = useDataPeriodInterval();
  const [settingsOpen, setSettingsOpen] = useState(false);

  const countryData = collectCountryData(visitors, period);

  useEffect(() => {
    if (tokenVerified) {
      dispatch(loadVisitors());
      dispatch(getTimeSpentData());
      dispatch(getLoadTimeData());
      dispatch(getFeedbackData());
      dispatch(getLoginData());
      dispatch(getDeletedData());
    }
  }, [tokenVerified]);

  return (
    <AuthorizedPage>
      <div>
        <PageHeader
          title="Home"
          hintMessage="Custom Analytics data"
          actions={[<SettingsButton onClick={() => setSettingsOpen(true)} />]}
        />
        <Grid className="dashboard-body">
          <Grid container>
            <Grid container item xs={12} spacing={2}>
              <Grid container item xs={12} md={12}>
                <SummaryButtons
                  data={[
                    {
                      title: 'Unique users registered',
                      value: visitors.filter((v) => isDateInInterval(v.created_at, period)).length,
                    },
                    {
                      title: 'Deleted accounts',
                      value: deletedData.filter((event) =>
                        isDateInInterval(event.created_at, period),
                      ).length,
                    },
                    {
                      title: 'Number of Countries',
                      value: countryData.length,
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid container item xs={12} spacing={2}>
              <Grid container item xs={12} md={6}>
                <TimePerRoomChart events={timeSpent} />
              </Grid>
              <Grid container item xs={12} sm={6} md={3}>
                <EventCountAnalyticsPanel
                  name="Total registrations per country"
                  category="Location"
                  data={countryData}
                  csvData={countryData.map((event) => ({
                    country: event.title,
                    noOfUsers: event.data,
                  }))}
                />
              </Grid>
              <Grid container item xs={12} sm={6} md={3}>
                <AnalyticsSummaryTable />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <DataRangeSettingsOverlay
        open={settingsOpen}
        period={period}
        onClose={() => setSettingsOpen(false)}
      />
    </AuthorizedPage>
  );
};

export default Home;
