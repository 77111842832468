import React, { useEffect } from 'react';

import { ZoomMtg } from '@zoomus/websdk';
import userService from '../service/userService';

const sdkKey = process.env.REACT_APP_ZOOM_SDK;

ZoomMtg.setZoomJSLib('/zoom/lib', '/av');
ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');

export interface ZoomClientViewProps {
  meetingId: string;
  password: string;
  name: string;
  email: string;
  tk?: string;
  onJoinSuccess?: () => void;
}

const ZoomClientView = (props: ZoomClientViewProps): JSX.Element => {
  const { meetingId, password, name, email, tk, onJoinSuccess } = props;

  useEffect(() => {
    const zoomElement = document.getElementById('zmmtg-root');
    if (zoomElement) {
      zoomElement.style.display = 'block';
      ZoomMtg.init({
        leaveUrl: `${window.location.origin}/zoom-thanks`,
        success: async (success: any) => {
          console.log('Zoom Mtg init success:', success);
          await userService.requestZoomSignature({ number: meetingId })
            .then((response) => ZoomMtg.join({
              sdkKey,
              signature: response.signature,
              meetingNumber: meetingId,
              passWord: password,
              userName: name,
              userEmail: email,
              tk,
              success: (successJ: any) => {
                console.log('Zoom Mtg join success:', successJ);

                if (onJoinSuccess) {
                  onJoinSuccess();
                }
                // // add the custom screen size once the meeting loads
                // const video = document.getElementsByClassName('video-share-layout');
                // console.log({ video });
                // if (video && video.length > 0) {
                //   video[0].classList.add('custom-screen-size');
                // }
              },
              error: (errorJ: any) => {
                console.error('Zoom Mtg join error:', errorJ);
              },
            }));
        },
        error: (error: any) => {
          console.error('Zoom Mtg init error:', error);
        },
      });
    }
  }, []);

  return (
    <div />
  );
};

export default ZoomClientView;
