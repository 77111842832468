import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILoginHistory, PermissionDetails, IVisitorDetails } from '../utilities/backendTypes';
import { copyTo } from '../utilities/functions';
import MESSAGES from '../constants/messagesConstants';
import userService from '../service/userService';
import authService from '../service/authService';
import { isTokenExpired } from './store';

const defaultUserDetails: IVisitorDetails = {
  id: -1,
  title: 'Dr',
  first_name: 'Fake',
  last_name: 'Person',
  specialty: 'Other',
  country: 'Australia',
  menarini_uc: '',
  created_at: new Date(),
  updated_at: new Date(),
  detailsLoaded: false,
  lastLogin: new Date().toISOString(),
} as const;

export interface UserState {
  currentUserId?: number;
  permissions: PermissionDetails[];
  visitors: IVisitorDetails[];
  token?: string;
  tokenLoaded: boolean;
  tokenVerified: boolean;
  passwordSet?: boolean;
  emailSent: boolean;
  message?: string;
  meetingSignature?: string;
  loginHistory?: ILoginHistory[];
}

export const initialState: UserState = {
  visitors: [] as IVisitorDetails[],
  permissions: [] as PermissionDetails[],
  token: '',
  emailSent: false,
  tokenLoaded: false,
  tokenVerified: false,
} as const;

export const verifyLogin = createAsyncThunk('verifyLogin', authService.verifyLogin);

export const loadVisitors = createAsyncThunk('loadVisitors', userService.loadVisitors);
export const requestZoomSignature = createAsyncThunk('requestZoomSignature', userService.requestZoomSignature);
export const forgotPassword = createAsyncThunk('forgotPassword', userService.forgotPassword);
export const resetPassword = createAsyncThunk('resetPassword', userService.resetPassword);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
      localStorage.setItem('token', state.token);
      state.tokenLoaded = true;
    },
    loadToken(state) {
      const token = localStorage.getItem('token');

      if (token) {
        state.token = token;
      }
      state.tokenLoaded = true;
    },
    logout() {
      localStorage.removeItem('token');
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyLogin.pending, (state, action) => {
        state.message = '';
      })
      .addCase(verifyLogin.fulfilled, (state, action) => {
        state.message = 'Login Success';
        state.tokenVerified = true;
      })
      .addCase(verifyLogin.rejected, (state, action) => {
        localStorage.removeItem('token');
        state.token = undefined;
        state.tokenLoaded = true;
        state.tokenVerified = false;
      })

      .addCase(loadVisitors.fulfilled, (state, action) => {
        state.visitors = action.payload.map((u) => copyTo(u, defaultUserDetails));
      })
      .addCase(loadVisitors.rejected, (state, action) => {
        if (!isTokenExpired(action.error.message ?? '')) {
          state.message = action.error.message;
        }
        state.passwordSet = false;
      })

      .addCase(forgotPassword.pending, (state) => {
        state.message = '';
        state.emailSent = false;
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.message = MESSAGES.RESET_PASSWORD_EMAIL_SENT;
        state.emailSent = true;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        if (!isTokenExpired(action.error.message ?? '')) {
          state.message = action.error.message;
        }
        state.emailSent = false;
      })
      .addCase(requestZoomSignature.pending, (state) => {
        state.message = '';
      })
      .addCase(requestZoomSignature.fulfilled, (state, action) => {
        state.meetingSignature = action.payload.signature;
      })
      .addCase(requestZoomSignature.rejected, (state, action) => {
        if (!isTokenExpired(action.error.message ?? '')) {
          state.message = action.error.message;
        }
      })

      .addCase(resetPassword.fulfilled, (state, action) => {
        state.passwordSet = true;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        if (!isTokenExpired(action.error.message ?? '')) {
          state.message = action.error.message;
        }
        state.passwordSet = false;
      });
  },
});

export const { setToken, loadToken, logout } = userSlice.actions;
export default userSlice.reducer;
