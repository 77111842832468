import React, { ChangeEvent, MouseEvent } from 'react';
import { MenuItem, TableCell, TableRow, TextField } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { ColumnData, RowData } from '../TableDefault';
import {
  ColumnToFilter,
  Filter,
  isNumericFilterOperator,
  isStringFilterOperator, NumericFilterOperators,
  StringFilterOperators,
} from '../Filter';

export interface FilterRowProps<Data extends RowData> {
  filter: Filter<Data>;
  fieldOptions: ColumnData<Data>[];
  onChange?: (filter: Filter<Data>) => void;
  onDelete?: (filter: Filter<Data>) => void;
}

const FilterOverlayRow = <Data extends RowData>(props: FilterRowProps<Data>) => {
  const { filter, fieldOptions, onChange, onDelete } = props;
  const { type, field, operator, value } = filter;

  const handleFieldChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const input = e.target.value as keyof Data;
    console.log('handleFieldChange', input);
    if (onChange) {
      const column = fieldOptions.find((c) => c.id === input);
      let copy: Filter<Data> = { ...filter };

      switch (type) {
        case 'string':
        case 'number':
        case 'date':
          if (column && column.type !== type) {
            // Going from Numeric to String
            copy = ColumnToFilter(column);
          } else {
            // Staying Numeric
            copy.field = input;
          }
          break;
        case 'empty':
          // Going from Empty to String or Numeric
          copy = ColumnToFilter(column);
          break;
        default:
          console.error(
            'Unexpected type encountered when changing filter field',
            type,
            input,
            column,
          );
      }

      onChange(copy);
    }
  };

  const handleOperatorChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const input = e.target.value;
    if (onChange) {
      const copy: Filter<Data> = { ...filter };
      if (type === 'string' && isStringFilterOperator(input)) {
        copy.operator = input;
        onChange(copy);
      } else if (type === 'number' && isNumericFilterOperator(input)) {
        copy.operator = input;
        onChange(copy);
      } else if (type === 'date' && isNumericFilterOperator(input)) {
        copy.operator = input;
        onChange(copy);
      }
    }
  };

  const handleValueChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const input = e.target.value;
    if (onChange) {
      const copy: Filter<Data> = { ...filter };
      copy.value = input;
      onChange(copy);
    }
  };

  const handleDelete = (e: MouseEvent<SVGSVGElement>) => {
    if (onDelete) {
      onDelete(filter);
    }
  };

  let customType = 'text';
  if (type) {
    switch (type) {
      case 'string':
        customType = 'empty';
        break;
      case 'number':
        customType = 'number';
        break;
      case 'date':
        customType = 'date';
        break;
      default:
        customType = '';
    }
  }

  return (
    <TableRow>
      <TableCell>
        <TextField select value={field ?? ''} onChange={handleFieldChange}>
          {fieldOptions.map((opt) => {
            const id = opt.id.toString();
            return (
              <MenuItem key={id} value={id}>
                {id}
              </MenuItem>
            );
          })}
        </TextField>
      </TableCell>
      <TableCell>
        <TextField
          select
          value={operator ?? ''}
          onChange={handleOperatorChange}
          disabled={type === 'empty'}
        >
          {(type === 'string' ? StringFilterOperators : NumericFilterOperators).map(
            (opt: string) => {
              const id = opt;
              return (
                <MenuItem key={id} value={id}>
                  {id}
                </MenuItem>
              );
            },
          )}
        </TextField>
      </TableCell>
      <TableCell>
        <TextField
          value={value ?? ''}
          onChange={handleValueChange}
          type={customType}
          disabled={type === 'empty'}
        />
      </TableCell>
      <TableCell>{onDelete && <Delete onClick={handleDelete} />}</TableCell>
    </TableRow>
  );
};

export default FilterOverlayRow;
