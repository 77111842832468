import React from 'react';
import { CSVLink } from 'react-csv';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import { ToolTippedButtonStyles } from './ToolTippedButton';

export interface DownloadCsvProps<T extends object> {
  downloadData: T[];
  fileName: string;
}

export default function DownloadCSVButton<T extends object>(props: DownloadCsvProps<T>) {
  const { downloadData, fileName } = props;
  const styles = ToolTippedButtonStyles();

  const formattedFileName = fileName.toLowerCase().replace(/ +/g, '_');
  return (
    <CSVLink data={downloadData} filename={`${formattedFileName}.csv`}>
      <Tooltip title="Download data">
        <IconButton aria-label="Download data" className={styles.button}>
          <CloudDownloadOutlinedIcon />
        </IconButton>
      </Tooltip>
    </CSVLink>
  );
}
