import React from 'react';
import { Grid } from '@material-ui/core';
import AnalyticsHistoryComponent, {
  AnalyticsHistoryComponentProps,
  AnalyticsTableData,
} from './AnalyticsHistoryComponent';
import { GRAPH_TYPES } from '../../constants/analyticsConstants';

export interface BrowsersListAnalyticsProps {
  name?: string;
  category?: string;
  data?: AnalyticsTableData<number>[];
  csvFile?: string;
  csvData: object[];
  formatter?: AnalyticsHistoryComponentProps<number>['formatter'];
}

const PercentageListAnalyticsPanel = (props: BrowsersListAnalyticsProps): JSX.Element => {
  const { name, category, data, csvFile, csvData, formatter = (v) => v.toFixed(2) } = props;

  const dummyData = [
    {
      id: 1,
      title: 'Foyer',
      data: 0,
      percentage: 0,
    },
    {
      id: 2,
      title: 'CardioConnect',
      data: 0,
      percentage: 0,
    },
    {
      id: 3,
      title: 'Angina Academy',
      data: 0,
      percentage: 0,
    },
    {
      id: 4,
      title: 'Product Showroom',
      data: 0,
      percentage: 0,
    },
  ] as AnalyticsTableData<number>[];

  return (
    <Grid container item xs={12} sm={12} md={12} lg={12}>
      {data && (
        <AnalyticsHistoryComponent
          name={name ?? 'Time per Room'}
          category={category ?? 'User'}
          type={
            data.length === 0
              ? GRAPH_TYPES.ANALYTICS_HISTORY_BROWSERS_EMPTY
              : GRAPH_TYPES.ANALYTICS_HISTORY_BROWSERS
          }
          data={data.length === 0 ? dummyData : data}
          csvFile={csvFile}
          csvData={csvData}
          formatter={formatter}
        />
      )}
    </Grid>
  );
};

export default PercentageListAnalyticsPanel;
