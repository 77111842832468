import Logger from 'redux-logger';
import { configureStore } from '@reduxjs/toolkit';
import Thunk from 'redux-thunk';
import Promise from 'redux-promise';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { combineReducers } from 'redux';
import PendingRequests, { hasPendingRequest } from '../utilities/PendingRequests';
import userSlice from './userSlice';
import analyticsSlice from './analyticsSlice';
import uiSlice from './uiSlice';

export const rootReducer = combineReducers({
  user: userSlice,
  analytics: analyticsSlice,
  pendingRequests: PendingRequests,
  uiData: uiSlice,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(Thunk, Promise, Logger),
});

export default store;

// auth token expires after some time, so we need to logout the user.
export const isTokenExpired = (message: string) => {
  const isExpired = message && message.includes('Token used too late');
  if (isExpired) {
    localStorage.removeItem('token');
    window.location.reload();
  }
  return isExpired;
};

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const hasAppPendingRequest = hasPendingRequest<RootState>((state) => state.pendingRequests);
export type RootState = ReturnType<typeof store.getState>;
