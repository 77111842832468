import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import CustomSnackbar, { CustomSnackbarProps } from '../../components/base/CustomSnackbar';

export default function (
  snackbarProps: Partial<CustomSnackbarProps>,
  timeout = 2500,
): [JSX.Element, Dispatch<SetStateAction<string>>] {
  const [message, setMessage] = useState('');
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    let id: NodeJS.Timeout;
    if (message) {
      setDisplay(true);
      setMessage(message);
      id = setTimeout(() => {
        setMessage('');
        setDisplay(false);
      }, timeout);
    }
    return () => clearTimeout(id);
  }, [message]);

  return [
    <CustomSnackbar severity="success" {...snackbarProps} message={message} open={display} />,
    setMessage,
  ];
}
