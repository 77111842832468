import { useState } from 'react';

// returns a function that generates the next unused key in a sequence.
export default function (initial: number = 0) {
  const [nextKey, setNextKey] = useState(initial);

  return {
    nextKey: () => {
      const next = nextKey;
      setNextKey((i) => i + 1);
      return next;
    },
    reset: (newInitial: number = 0) => {
      setNextKey(newInitial);
    },
  };
}
