import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { IconButton, Tooltip } from '@material-ui/core';
import TableDefaultToolbar from './TableDefault/TableDefaultToolbar';
import TableDefaultHead from './TableDefault/TableDefaultHead';
import { getComparator, stableSort } from './TableDefault/utils';
import FilterOverlay from './TableDefault/FilterOverlay';
import { Filter } from './Filter';
import { Key } from '../../utilities/frontendTypes';

export type Order = 'asc' | 'desc';

// Type used for the tables header
export type RowData = { id: Key };

export interface ColumnData<Data extends RowData> {
  id: keyof Data;
  type: 'string' | 'number' | 'date';
  disablePadding?: boolean;
  label?: string;
  formatter?: (value: any) => React.ReactNode;
}

interface Action<Data extends RowData> {
  icon: (props: any) => JSX.Element;
  action: (item: Data) => void;
  tooltip?: string;
  key: Key;
}

interface TableDefaultProps<Data extends RowData> {
  columns: ColumnData<Data>[];
  rows: Data[];
  filters: Filter<Data>[];
  onFiltersUpdate?: (filters: Filter<Data>[]) => void;
  actionsColumnHeading?: string;
  actions?: Action<Data>[];
  onMassDelete?: (items: Data[]) => void;
  onAddNew?: () => void;
  viewDataOnly?: boolean | undefined;
  onRowSelect?: (selectedIndexes: Key[]) => void;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSearch?: () => void;
  handleClearSearch?: () => void;
  searchInputData?: string;
  setSearchInputData?: (input: string) => void;
  selectOnlyOneRow?: boolean;
  hideHeader?: boolean;
  hideFilterButton?: boolean;
  hideAddButton?: boolean;
  hideDeleteButton?: boolean;
  hideDownloadButton?: boolean;
  hideSearchButton?: boolean;
  unSelectAllRows?: boolean;
  csvData: object[];
  csvFile: string;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    row: {
      verticalAlign: 'center',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    actionColumn: {
      width: 1,
      whiteSpace: 'nowrap',
    },
    actionCell: {
      width: 1,
      whiteSpace: 'nowrap',
    },
    actionIcons: {
      fontSize: 19,
      margin: 8,
    },
  }),
);

function TableDefault<Data extends RowData>(props: TableDefaultProps<Data>): JSX.Element {
  const {
    columns,
    rows,
    filters,
    onFiltersUpdate,
    actionsColumnHeading,
    actions,
    onMassDelete,
    onAddNew,
    viewDataOnly,
    onRowSelect,
    onSelectAllClick,
    selectOnlyOneRow,
    hideHeader,
    hideAddButton,
    hideDeleteButton,
    hideFilterButton,
    hideDownloadButton = true,
    hideSearchButton = true,
    unSelectAllRows,
    csvData,
    csvFile,
    handleSearch,
    handleClearSearch,
    setSearchInputData,
    searchInputData,
  } = props;
  const classes = useStyles();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>(columns[0].id);
  const [selected, setSelected] = useState<Key[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showFilter, setShowFilter] = useState(false);

  const hasActions: boolean = actions !== undefined;
  const isSelected = (name: Key) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onSelectAllClick) onSelectAllClick!(event);

    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<HTMLTableRowElement>, name: Key) => {
    if (!viewDataOnly) {
      const selectedIndex = selected.indexOf(name);
      let newSelected: Key[] = [];

      if (selectOnlyOneRow && selectedIndex === -1) {
        // allow to select only one row
        newSelected[0] = name;
      } else if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }

      setSelected(newSelected);
      if (onRowSelect) {
        onRowSelect(newSelected); // callback to parent component
      }
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleToggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleDeleteSelected = () => {
    const toDelete: Data[] = [];

    selected.forEach((key) => {
      const item = rows.find((r) => r.id === key);
      if (item) {
        toDelete.push(item);
      }
    });

    if (onMassDelete && toDelete?.length) {
      onMassDelete(toDelete);
    }
  };

  /*
   * unselect all table rows
   */
  useEffect(() => {
    if (unSelectAllRows) {
      setSelected([]);
    }
  }, [unSelectAllRows]);

  return (
    <div className={classes.root}>
      <Paper className={`${classes.paper} table-paper`}>
        {
          !hideHeader &&
          <TableDefaultToolbar
            numSelected={selected.length}
            onClickFilter={handleToggleFilter}
            onClickDelete={handleDeleteSelected}
            onClickAdd={onAddNew || (() => {})}
            viewDataOnly={viewDataOnly}
            hideFilterButton={hideFilterButton || false}
            hideAddButton={hideAddButton || false}
            hideDeleteButton={hideDeleteButton || false}
            hideDownloadButton={hideDownloadButton}
            hideSearchButton={hideSearchButton}
            csvData={csvData}
            csvFile={csvFile}
            handleSearch={handleSearch}
            handleClearSearch={handleClearSearch}
            setSearchInputData={setSearchInputData}
            searchInputData={searchInputData}
          />
        }
        <TableContainer className="table-container">
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <TableDefaultHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              columns={columns}
              actionsColumn={hasActions}
              actionsColumnHeading={actionsColumnHeading}
              viewDataOnly={viewDataOnly}
            />
            <TableBody>
              {stableSort(rows, getComparator<Data>(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const rowId = `row-${row.id}-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={rowId}
                      selected={isItemSelected}
                      className={classes.row}
                    >
                      {!viewDataOnly && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell>
                      )}
                      {columns.map((c, i) => (
                        <TableCell
                          align="left"
                          key={c.id.toString()}
                        >
                          {c.formatter ? c.formatter(row[c.id]) : row[c.id]}
                        </TableCell>
                      ))}
                      {hasActions && (
                        <TableCell classes={{ root: classes.actionCell }}>
                          {actions &&
                            actions.map((a) => {
                              let component = (
                                <IconButton
                                  size="small"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    a.action(row);
                                  }}
                                  key={a.key}
                                >
                                  <a.icon classes={{ root: classes.actionIcons }} />
                                </IconButton>
                              );
                              if (a.tooltip) {
                                component = (
                                  <Tooltip title={a.tooltip} key={a.key}>
                                    {component}
                                  </Tooltip>
                                );
                              }
                              return component;
                            })}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FilterOverlay
        open={showFilter}
        columns={columns}
        filters={filters}
        onClose={handleToggleFilter}
        onSave={onFiltersUpdate}
      />
    </div>
  );
}

export default TableDefault;
